<div class="editpatient--body newrx--body edit--drug">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-12 padding-0">
                <div class="row">
                    <div class="reportsicon padding-0">
                        <i class="fas fa-file-chart-line customicon"> </i>
                    </div>
                    <div class="col-10 margin_left_n1rem">
                        <div class="content--heading__title padding-0"> Report Preview</div>
                    </div>
                    <!-- <div class="col-md-1 padding_left_7rem">
                        <app-user-guide-icon [ActiveTabId]= "'PartialFillReport'"></app-user-guide-icon>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading"> {{selcReprtName | uppercase}} </span>
                </div>
            </div>
        </div>
        <div class="eprx--block__content reports_default_height row mx-0">
            <div class="col-md-4 padding-0 reportPrevDisplay">
                <div class="row">
                    <div class="col-md-12 insurance--body p-0">
                        <div *ngFor="let dateType of datePickers; let j=index" class="row col-lg-12 col-md-12">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-date-picker [RxSelectId]="'fromdate'+j" [LabelText]="dateType.filterName === 'Fill Date' ? fillFrmDisplay : 
                                dateType.filterName === 'Ord Date' ? ordFrmDisplay : dateType.filterName === 'Due Date' ? dueDtFrmDisplay : 
                                dateType.filterName === 'Due Date by Consumption Threshold' ? dueDtThrshFrmDisplay : dateType.filterName === 'Due Date from based on Pickup Date' ? dueDtPckUpFrmDisplay : dateType.filterName === 'Picked Up Date' ? pickUpDtFrmDisplay :dateType.filterName === 'Date Of Accident' ? accDtFrmDisplay : ''"
                                [PlaceHolder]="''" [ControlName]="dateType.filterName === 'Fill Date' ? cntrlFillFrm : 
                                dateType.filterName === 'Ord Date' ? cntrlOrdFrm : dateType.filterName === 'Due Date' ? cntrlDueFrm : 
                                dateType.filterName === 'Due Date by Consumption Threshold' ? cntrlDueDtThrshFrm : dateType.filterName === 'Due Date from based on Pickup Date' ? cntrlDueDtPckUpFrm : dateType.filterName === 'Picked Up Date' ? cntrlPickUpDtFrm :dateType.filterName === 'Date Of Accident' ? cntrlAccDtFrm : ''" [FormGroupName]="previewFG"
                                [MinDate]="" [IsDisabled]="false" [MaxDate]="maxDate">
                                </eprx-date-picker>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-date-picker [RxSelectId]="'todate'+j" [LabelText]="dateType.filterName === 'Fill Date' ? fillToDisplay : 
                                dateType.filterName === 'Ord Date' ? ordToDisplay : dateType.filterName === 'Due Date' ? dueDtToDisplay : 
                                dateType.filterName === 'Due Date by Consumption Threshold' ? dueDtThrshToDisplay : dateType.filterName === 'Due Date from based on Pickup Date' ? dueDtPckUpToDisplay : dateType.filterName === 'Picked Up Date' ? pickUpDtToDisplay :dateType.filterName === 'Date Of Accident' ? accDtToDisplay : ''"
                               [PlaceHolder]="''"
                                    [ControlName]="dateType.filterName === 'Fill Date' ? cntrlFillTo : 
                                    dateType.filterName === 'Ord Date' ? cntrlOrdTo : dateType.filterName === 'Due Date' ? cntrlDueTo : 
                                    dateType.filterName === 'Due Date by Consumption Threshold' ? cntrlDueDtThrshTo : dateType.filterName === 'Due Date from based on Pickup Date' ? cntrlDueDtPckUpTo : dateType.filterName === 'Picked Up Date' ? cntrlPickUpDtTo :dateType.filterName === 'Date Of Accident' ? cntrlAccDtTo : ''" [FormGroupName]="previewFG" [MinDate]=""
                                    [IsDisabled]="false" [MaxDate]="maxDate">
                                </eprx-date-picker>
                            </div> 
                        </div>
                        <div *ngFor="let sgsts of suggestFilters; let j=index" class="row col-lg-12 col-md-12">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 seconday-search--grid">
                                <label *ngIf="(sgsts?.filterName !== 'Drugs')">{{(sgsts?.filterName === 'Prescribers') ? 'Prescriber' : (sgsts?.filterName === 'Patients') ? 'Patient' : ''}}</label>
                                <div class="seconday-search--grid" *ngIf="(sgsts?.filterName === 'Prescribers')">
                                    <app-searchbox-global class="common_global_search_max_content_display_for_ReportWriter"
                                        [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [IsAddNew]="false"
                                        (SelectedPrescriber)="selectedPresInfo($event)" [InputValue]="prescInputValue">
                                    </app-searchbox-global>
                                </div>
                                <div class="row" *ngIf="(sgsts?.filterName === 'Drugs')">
                                    <label class="col-4">Drug</label>
                                    <div class="col-8">
                                        <!-- <div class="text-right">
                                            <div><i class="far fa-lg fa-long-arrow-alt-right color_orange"></i><span> for Equv ;
                                                </span><b class="color_orange">Shift + <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                        ></i></b><span> for Gen Equv</span>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="seconday-search--grid" *ngIf="(sgsts?.filterName === 'Drugs')">
                                <app-searchbox-global class="common_global_search_max_content_display_for_ReportWriter" id="Drug" [RxSelectId]="'DrugPres'"
                                [SearchFor]="'Drug'" [IsAddNew]="false" [DisableSerchFil]="true"
                               [DisableRadioButtons]="true" (SelectedDrug)="selectDrug($event)" [IsHideDrugFilters]="true" [ClearInputValue]="drugInputValue">
                                </app-searchbox-global>
                                </div>
                                <div class="seconday-search--grid" *ngIf="(sgsts?.filterName === 'Patients')">
                                    <app-searchbox-global
                                id="Patient"
                                class="common_global_search_max_content_display_for_ReportWriter"
                                [RxSelectId]="'Patient'"
                                [SearchFor]="'Patient'"
                                [ShowOnlyPrescName]="true"
                                [IsAddNew]="false"
                                [DisableSerchFil]="true"
                                [DisableRadioButtons]="true"
                                (SelectedPatient)="selectPatient($event)"
                                [ClearInputValue]="patientInputvalue"
                                    ></app-searchbox-global>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let dropDwn of dropDowns; let j=index" class="row col-lg-12 col-md-12">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select
                                [RxSelectId]="'dropdown'+j"
                                [LabelText]="dropDwn.filterName === 'Insurances'? displayTextIns : dropDwn.filterName === 'Facility' ? displayTextFac : 
                                dropDwn.filterName === 'Patient Category' ? displayTextPat : dropDwn.filterName === 'Drug Type' ? displayTextDgType : 
                                dropDwn.filterName === 'Drug Class' ? displayTextDgClass : dropDwn.filterName === 'Drug Brand' ? displayTextDgBrnd :
                                dropDwn.filterName === 'Payor' ? displayTextPyr : dropDwn.filterName === 'DAW Values' ? displayTextDAW : dropDwn.filterName === 'Rx Origin' ? displayTextOrg : dropDwn.filterName === 'Pickup Status' ? displayTextPckUp : dropDwn.filterName === 'Rx Transfer Status' ? displayTextTrans : dropDwn.filterName === 'Pharmacist' ?displayTextPharm: dropDwn.filterName === 'Rx Status' ? displayTextRxSt : dropDwn.filterName === 'Inventory Bucket'?displayTextInvBck : dropDwn.filterName === 'Exclude Insurances' ? displayTextExcIns : ''"
                                [PlaceHolder]="dropDwn.filterName === 'Insurances'? displayTextIns : dropDwn.filterName === 'Facility' ? displayTextFac : 
                                dropDwn.filterName === 'Patient Category' ? displayTextPat : dropDwn.filterName === 'Drug Type' ? displayTextDgType : 
                                dropDwn.filterName === 'Drug Class' ? displayTextDgClass : dropDwn.filterName === 'Drug Brand' ? displayTextDgBrnd :
                                dropDwn.filterName === 'Payor' ? displayTextPyr : dropDwn.filterName === 'DAW Values' ? displayTextDAW : dropDwn.filterName === 'Rx Origin' ? displayTextOrg : dropDwn.filterName === 'Pickup Status' ? displayTextPckUp : dropDwn.filterName === 'Rx Transfer Status' ? displayTextTrans : dropDwn.filterName === 'Pharmacist' ?displayTextPharm: dropDwn.filterName === 'Rx Status' ? displayTextRxSt : dropDwn.filterName === 'Inventory Bucket'?displayTextInvBck : dropDwn.filterName === 'Exclude Insurances' ? displayTextExcIns : ''"
                                [Title]="dropDwn.filterName === 'Insurances'? displayTextIns : dropDwn.filterName === 'Facility' ? displayTextFac : 
                                dropDwn.filterName === 'Patient Category' ? displayTextPat : dropDwn.filterName === 'Drug Type' ? displayTextDgType : 
                                dropDwn.filterName === 'Drug Class' ? displayTextDgClass : dropDwn.filterName === 'Drug Brand' ? displayTextDgBrnd :
                                dropDwn.filterName === 'Payor' ? displayTextPyr : dropDwn.filterName === 'DAW Values' ? displayTextDAW : dropDwn.filterName === 'Rx Origin' ? displayTextOrg : dropDwn.filterName === 'Pickup Status' ? displayTextPckUp : dropDwn.filterName === 'Rx Transfer Status' ? displayTextTrans : dropDwn.filterName === 'Pharmacist' ?displayTextPharm: dropDwn.filterName === 'Rx Status' ? displayTextRxSt : dropDwn.filterName === 'Inventory Bucket'?displayTextInvBck : dropDwn.filterName === 'Exclude Insurances' ? displayTextExcIns : ''"
                                [ControlName]="dropDwn.filterName === 'Insurances'? CntrlNameIns : dropDwn.filterName === 'Facility' ? CntrlNameFac : 
                                dropDwn.filterName === 'Patient Category' ? CntrlNamePat : dropDwn.filterName === 'Drug Type' ? CntrlNameDgType :
                                dropDwn.filterName === 'Drug Class' ? CntrlNameDgClass : dropDwn.filterName === 'Drug Brand' ? CntrlNameDgBrnd : 
                                dropDwn.filterName === 'Payor' ? CntrlNamePyr : dropDwn.filterName === 'DAW Values' ? CntrlNameDAW : dropDwn.filterName === 'Rx Origin' ? CntrlNameOrg : dropDwn.filterName === 'Pickup Status' ? CntrlNamePckUp : dropDwn.filterName === 'Rx Transfer Status' ? CntrlNameTrans : dropDwn.filterName === 'Pharmacist' ?CntrlNamePharm: dropDwn.filterName === 'Rx Status' ? CntrlNameRxSt : dropDwn.filterName === 'Inventory Bucket'?CntrlNameInvBck : CntrlNameExcIns"
                                [FormGroupName]="previewFG"
                                [HasMultiple]="dropDwn.isMultiSelect ? true : false"
                                [HasBindLabel2]="dropDwn.showCodeAndDesc ? true : false"
                                [Show2Values]="dropDwn.showCodeAndDesc ? true : false"
                                [BindLabel2]="dropDwn.filterName === 'Pharmacist'?'lastName': dropDwn.filterName === 'DAW Values' ? 'Name' : dropDwn.filterName !== 'Rx Status' &&dropDwn.showCodeAndDesc ? 'description' : ''"
                                [BindLabel]="dropDwn.filterName === 'Pharmacist' ? 'firstName' : dropDwn.filterName === 'DAW Values' ? 'Code' :
                                dropDwn.filterName === 'Inventory Bucket' ? 'Name': 'code'"
                                [BindValue]="dropDwn.filterName === 'DAW Values' || dropDwn.filterName === 'Inventory Bucket' ? 'Id' : (dropDwn.filterName === 'Insurances' ||
                                dropDwn.filterName === 'Facility' || dropDwn.filterName === 'Patient Category' ||  dropDwn?.filterName === 'Exclude Insurances')  ? 'code' : 'id'"
                                [LabelForId]="''"
                                [LabelTitle1]="dropDwn.filterName === 'Pharmacist'? 'First Name' : 'Code'"
                                [LabelTitle2]="dropDwn.filterName === 'Pharmacist'? 'Last Name' : dropDwn.showCodeAndDesc ? 'Description' : ''"
                                [List]="dropDwn.filterName === 'Insurances'? totalInsurance: 
                                dropDwn.filterName === 'Facility' ? totalFacility: dropDwn.filterName === 'Patient Category'? patCatData: dropDwn.filterName === 'Drug Type' ? totalDrugType: dropDwn.filterName === 'Drug Class'? drugClassList: dropDwn.filterName === 'Drug Brand' ? drugBrandList : dropDwn.filterName === 'Payor'? dropDownList?.payor : dropDwn.filterName === 'DAW Values' ? systemData?.DAW : dropDwn.filterName === 'Rx Origin'? dropDownList?.rxOrigin: dropDwn.filterName === 'Pickup Status' ? pickUpList : dropDwn.filterName === 'Rx Transfer Status'? rxTransferList : dropDwn.filterName === 'Pharmacist' ? dropDownList?.pharmacist : dropDwn.filterName === 'Rx Status' ? totalRxStatus : dropDwn.filterName === 'Inventory Bucket'? (bucketList$ | async) : dropDownList?.insurance"
                                (TriggerSelectValue)="dropDwn.filterName === 'Rx Status'? getStatusChange(dropDwn.filterName) :''"
                            >
                            </eprx-select>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div *ngFor="let chckBox of Checkboxes; let i=index" class="col-lg-6 col-md-6 col-sm-6 col-xs-12 height_2_5rem">
                                        <eprx-check-box [LabelText]="chckBox?.filterName === 'ActivePatient Only' ? checkBoxEnums?.ActvPatientOnly : chckBox?.filterName === 'OriginalRxs Only' ? checkBoxEnums?.OriginalRxOnly :
                                        chckBox?.filterName === 'Refills Only' ? checkBoxEnums?.RefillsOnly :
                                        chckBox?.filterName === 'Facility Patients Only' ? checkBoxEnums?.FacPatientsOnly :
                                        chckBox?.filterName === 'Exclude Facility Patients' ? checkBoxEnums?.ExcldFacPatients :
                                        chckBox?.filterName === 'Cash Rxs Only' ? checkBoxEnums?.CashRxOnly :
                                        chckBox?.filterName === 'Ins Paid Rxs Only' ? checkBoxEnums?.InsPaidRxOnly :
                                        chckBox?.filterName === 'Include Unbilled' ? checkBoxEnums?.IncldUnbilled :
                                        chckBox?.filterName === 'Include Deferred' ? checkBoxEnums?.IncldDeferred :
                                        chckBox?.filterName === 'Compound Rxs Only' ? checkBoxEnums?.CompoundRxOnly :
                                        chckBox?.filterName === 'Include 340B Drugs' ? checkBoxEnums?.Incld340BDrug :
                                        chckBox?.filterName === 'Auth Refill Greater than Zero' ? checkBoxEnums?.AuthRefGrtZero :
                                        chckBox?.filterName === 'Missing Serial Numbers Only' ? checkBoxEnums?.MissingSerialNumOnly :
                                        ''" [ControlName]="chckBox?.filterName === 'ActivePatient Only' ? checkBoxEnums?.isActvPatient : chckBox?.filterName === 'OriginalRxs Only' ? checkBoxEnums?.isOrginalRx :
                                        chckBox?.filterName === 'Refills Only' ? checkBoxEnums?.isRefillsOnly :
                                        chckBox?.filterName === 'Facility Patients Only' ? checkBoxEnums?.isFacPatOnly :
                                        chckBox?.filterName === 'Exclude Facility Patients' ? checkBoxEnums?.isExcldFacPat :
                                        chckBox?.filterName === 'Cash Rxs Only' ? checkBoxEnums?.isCashRxOnly :
                                        chckBox?.filterName === 'Ins Paid Rxs Only' ? checkBoxEnums?.isInsPaidRxOnly :
                                        chckBox?.filterName === 'Include Unbilled' ? checkBoxEnums?.incldUnbilled :
                                        chckBox?.filterName === 'Include Deferred' ? checkBoxEnums?.incldDefered :
                                        chckBox?.filterName === 'Compound Rxs Only' ? checkBoxEnums?.isCompoundRxOnly :
                                        chckBox?.filterName === 'Include 340B Drugs' ? checkBoxEnums?.is340BDrug :
                                        chckBox?.filterName === 'Auth Refill Greater than Zero' ? checkBoxEnums?.isAuthRefMoreThanZero :
                                        chckBox?.filterName === 'Missing Serial Numbers Only' ? checkBoxEnums?.isMissingSerialNumOnly :
                                        ''" [FormGroupName]="previewFG">
                                        </eprx-check-box>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-md-6 messsage-height">
                                    <label class="col-md-12 padding-0">
                                        Page Orientation
                                    </label>
                                    <eprx-radio [ControlName]="'pageOrientation'" [FormGroupName]="reportParamsFG" [List]="['Portrait', 'Landscape']" [ValueList]="[false, true]"
                                        [IDForList]="['portrait', 'landscape']" [LabelForId]="">
                                    </eprx-radio>
                                </div>
                                <div class="col-md-6 messsage-height">
                                    <label class="col-md-12 padding-0">
                                        Report Type
                                    </label>
                                    <eprx-radio [ControlName]="'reportType'" [FormGroupName]="reportParamsFG" [List]="['Detail', 'Summary']" [ValueList]="[1, 2]"
                                        [IDForList]="['detail', 'sumamry']" [LabelForId]="" [IsDisabled]="true">
                                    </eprx-radio>
                                </div>
                            </div>
                        </div>
                        

                        <div class="col-md-12">
                            <button type="submit" *ngIf = "!reportIsInProgress" class="hotkey_success pull-right" (click)="previewReport(true, false, 'print')" appShortcutKey [AltKey]="'p'"
                                (keydown.enter)="previewReport(true, false, 'print')" id="Print">
                                <span>P</span> Print
                            </button>
                            <button type="submit" *ngIf = "reportIsInProgress" class="inactive pull-right"
                             id="Print" [title] ="reportIsInProgress"><span>P</span>
                                Print
                            </button>
                            <button type="submit" *ngIf = "!reportIsInProgress" class="hotkey_success pull-right" (click)="previewReport(false, false, 'preview')" appShortcutKey [AltKey]="'v'"
                                (keydown.enter)="previewReport(false, false, 'preview')" id="Preview">
                                <span>V</span> Preview
                            </button>
                            <button type="submit" *ngIf = "reportIsInProgress" class="inactive pull-right"id="Preview"  [title] ="reportIsInProgress">
                            <span>V</span>
                            Preview
                            </button>
                            <button type="submit" class="hotkey_success pull-right" *ngIf = "!reportIsInProgress" (click)="previewReport(false,true, 'export')" appShortcutKey [AltKey]="'j'"
                                (keydown.enter)="previewReport(false,true, 'export')" id="Export">
                                <span>J</span> Export
                            </button>
                            <button type="submit" class="inactive pull-right" *ngIf = "reportIsInProgress" id="Export" [title] ="reportIsInProgress"
                            ><span>J</span> Export
                            </button>
                            <button type="button" (keydown.tab)="closePrevReport()" appShortcutKey [AltKey]="'c'"
                                class="hotkey_success pull-right" (keydown.enter)="closePrevReport()" id="Cancel"
                                (click)="closePrevReport()">
                                <span>C</span> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8" *ngIf="showPreview">
                <iframe [src]="pdfContent" class="text-center" toolbar="0" width="100%" height="100%" type="application/pdf"></iframe>
            </div>
            <div class="col-md-8" *ngIf="!showPreview">
                <h3 class="reports--condi"> Please click on "PREVIEW" button to see reports </h3>
            </div>
            <!-- <div class="col-md-8" *ngIf="prevFilters?.length">
                <h3 class="reports--condi"> Please click on "PREVIEW" button to see reports </h3>
            </div> -->
        </div>
    </div>
</div>
