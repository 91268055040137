<div class="exprx--common-block back-dark-color help-text-blk" appDrag appDragHandle  *ngIf="showHelpText">
    <div class="eprx--header__heading back-dark-color p-1" >
        <div class="eprx--header__heading">
            <h3 class="header--sml__htex color-gold text-center fw-600">
                <div class="row pt-2">
                    <div class="col-8 ml-5 text-center">
                        COLOR LEGEND
                    </div>
                    <div class="col-2 text-right pr-4" (click)="closeHelpText()">
                        <i class="fas fa-times cursor-pointer"></i>
                    </div>
                </div>
             </h3>
        </div>
    </div>
    <div class="eprx--block__content">
        <div *ngIf="RxDrSt?.RxStatus">
        <h5 class="text-left pl-2">Rx Color Coding</h5>
        <div class="row p-0 rx--his" >
            <div class="col-12">
                <div class="color-box his-bg--red__text--white" >
                    Discontinued Rx
                </div>
                <div class="color-box his-bg--grey__text--white" >
                    Cancel and Discontinued Rx
                </div>
                <div class="color-box his-bg--blue__text--white" >
                    Deferred Rx
                </div>
                <div class="color-box his-bg--darkgreen__text--white" >
                    Refill Due
                </div>
                <div class="color-box his-bg--white__text--black" >
                    Normal/Expired(User) Rx
                </div>
                <div class="color-box his-bg--peach__color--white" >
                    Expired Rx
                </div>
                <div class="color-box his-bg--lightgreen__text--white" >
                    Early to Refill
                </div>
                <div class="color-box his-bg--blue_peach__text--black" >
                    Deferred and Expired Rx
                </div>
                <div class="color-box his-bg--blue_white__text--black" >
                    Deferred and Expired(User) Rx
                </div>
                <div class="color-box his-bg--blue_red__text--white" >
                    Deferred and Discontinued Rx
                </div>
                <div class="color-box his-bg--blue_grey__text--white" title="Deferred and Discontinued from Cancel Rx">
                    Deferred & Discontinued from Cancel Rx
                </div>
                <div class="color-box his-bg--peach_red__text--black" >
                    Expired and Discontinued Rx
                </div>
                <div class="color-box his-bg--peach_grey__text--black" title="Expired and Discontinued from Cancel Rx">
                    Exp & Discontinued from Cancel Rx
                </div>
                <div class="color-box his-bg--white_red__text--black" >
                    Expired(User) and Discontinued Rx
                </div>
                <div class="color-box his-bg--white_grey__text--black" title="Expired(User) and Discontinued from Cancel Rx">
                    Exp(User) & Discontinued from Cancel Rx
                </div>
            </div>
            <div class="col-12">
                <hr class="m-0">
            </div>
        </div>
        </div>
        <div *ngIf="RxDrSt?.DrugName">
        <h5 class="text-left pl-2">Drug Color Coding</h5>
        <div class="row p-0 wizard-color-code">
            <div class="col-12" *ngIf="!RxDrSt?.frmErx && !RxDrSt?.frmEDI810">
                <div class="color-box his-bg--orange__text--blue" >
                    Control Deferred Rx
                </div>
                <div class="color-box his-bg--white__text--blue" >
                    Non-Control Deferred Rx
                </div>
                <div class="color-box his-bg--orange__text--red" >
                    Control Rx Not PickedUp
                </div>
                <div class="color-box his-bg--white__text--red" >
                    Non-Control Rx Not PickedUp
                </div>
                <div class="color-box his-bg--orange__text--black" >
                    Control PickedUp Rx
                </div>
                <div class="color-box his-bg--white__text--black" >
                    Non-Control PickedUp Rx
                </div>
            </div>
            <div class="col-12" *ngIf="!RxDrSt?.frmEDI810">
                <hr class="m-0">
            </div>
            <div class="col-12" *ngIf="RxDrSt?.frmErx || RxDrSt?.frmIntakeQueue || RxDrSt?.frmEDI810">
                <div class="color-box" [ngClass]="RxDrSt?.frmEDI810 ? 'his-bg-white_text-orange' : 'his-bg--orange__text--black font_weight_normal'" >
                    Control Drug
                </div>
                <div class="color-box his-bg--white__text--black font_weight_normal">
                    Non-Control Drug
                </div>
            </div>
        </div>
        </div>
        <div *ngIf="RxDrSt?.Status">
        <h5 class="text-left pl-2">Status Color Coding</h5>
        <div class="row p-0 wizard-color-code">
            <!-- <div class="col-12">
                <hr class="m-0">
            </div> -->
            <div class="col-12">
                <div class="color-box his-bg--red__text--white" >
                    Unbilled Rx
                </div>
                <div class="color-box his-bg--darkgreen__text--white" >
                    Billed Rx
                </div>
                <div class="color-box his-bg--blue_text--white" >
                    Deferred Rx
                </div>
                <div class="color-box his-bg--disabled__text--white" >
                   T-Transferred Out Rx
                </div>
                <div class="color-box his-bg--white__text--black" >
                    UI-Unbilled Transferred In Rx
                </div>
                <div class="color-box his-bg--white__text--black" >
                    BI-Billed Transferred In Rx
                </div>
            </div>
        </div>
        </div>
        <div *ngIf="RxDrSt?.DrugForDrugFile">
            <h5 class="text-left pl-2">Drug Color Coding</h5>
            <div class="row p-0 wizard-color-code">
                <div class="col-12">
                    <div class="color-box his-bg--red_text-brown_with_text_black color_legend_vertical_line">
                        Discontinued & Preferred Drug
                    </div>
                    <div class="color-box  his-bg--green_text-black  color_legend_vertical_line">
                        Last Dispensed & Preferred Drug
                    </div>
                    <div class="color-box his-bg--red_text-brown_with_text_black ">
                        Discontinued Drug
                    </div>
                    <div class="color-box his-bg--green_text-black">
                        Last Dispensed Drug
                    </div>
                    <div class="color-box  color_legend_vertical_line help_preferred_drug">
                        Preferred Drug
                    </div>
                    <div class="color-box his-bg--white_text-black">
                        Normal Drug
                    </div>
                    <div class="color-box his-bg--white_text-black height_45" *ngIf="RxDrSt?.isNotAssociate">
                        <div class="drug-price-notExist"  [title]="'Drug not associated with the selected Bucket.'"></div>
                        <span  class ="position-relative top_n5px">
                    Drug Not Associated with Bucket</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="RxDrSt?.DrugForGridDrugFile">
            <h5 class="text-left pl-2">Drug Color Coding</h5>
            <div class="row p-0 wizard-color-code">
                <div class="col-12">
                    <div class="color-box his-bg-white_text-red color_legend_vertical_line_drug_grid mb-2">
                        Discontinued & Preferred Drug
                    </div>
                    <div class="color-box his-bg--white_text-green color_legend_vertical_line_drug_grid mb-2">
                        Last Dispensed & Preferred Drug
                    </div>
                    <div class="color-box his-bg-white_text-red mb-2">
                        Discontinued Drug
                    </div>
                    <div class="color-box his-bg--white_text-green mb-2">
                        Last Dispensed Drug
                    </div>
                    <div class="color-box color_legend_vertical_line_drug_grid help_preferred_drug_grid mb-2">
                        Preferred Drug
                    </div>
                    <div class="color-box his-bg--white_text-black help_preferred_drug_grid mb-2">
                        Normal Drug
                    </div>
                    <div *ngIf="RxDrSt?.isNotAssociate" [title]="'Drug not associated with the selected Bucket.'" class="color-box his-bg--white_text-black_drug_grid color_legend_vertical_line-notassign_drug_grid">
                        Drug Not Associated with Bucket
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

