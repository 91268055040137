<ng-template #OverRide let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Override Action</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="font-size_13">
                    User does not have privilege to perform <b *ngIf="!frmDPVVerf"> "{{(catId | StringSepWithSpaceOnCamCase)}} - </b>
                    <b>{{(privMask | StringSepWithSpaceOnCamCase)}}" </b> Action.
                    <br /> To Override this action, enter the Override Credentials.
                </div>
            </div>
            <hr />
            <div class="col-12">
                <div class="exprx--common-block ">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <span>Override Credentials</span>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-sm-12 padding-0 erx--body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input  [AutoFocus]="true" [LabelText]="'User Id'" [PlaceHolder]="'User Id'" [ControlName]="'PharmUsername'" [FormGroupName]="OverrideLogFG" >
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [InputType]="'PASSWORD'" [LabelText]="'Password'" [PlaceHolder]="'Password'" [ControlName]="'PharmPassword'" [FormGroupName]="OverrideLogFG">
                                    </eprx-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="setOverrideLog()" appShortcutKey [AltKey]="'o'">
                  <span>O</span> Ok
                </button>
                <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</ng-template>
<div *ngIf="isComp && !IsNotesDisplay">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!CommentsOpen">Override Action</h4>
        <h4 class="modal-title" *ngIf="CommentsOpen">Comments</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="!CommentsOpen ? closeModal() : closeCommentModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12" *ngIf="!CommentsOpen">
                <div *ngIf="(!CommentsOpen && isConsolidated) || IsFrmInfo" class="font-size_13">
                    Authorization is required for
                    <b>"{{privMask | StringSepWithSpaceOnCamCase:'Consolidated'}}" </b> for the selected Rx.
                    <br /> Please provide Authorization below:
                </div>
                <div *ngIf="(!CommentsOpen && !isConsolidated) && !IsFrmInfo" class="font-size_13">
                    User does not have privilege to perform <b> "{{(catId | StringSepWithSpaceOnCamCase)}} - </b>
                    <b>{{(privMask | StringSepWithSpaceOnCamCase)}}" </b> Action.
                    <br /> To Override this action, enter the Override Credentials.
                </div>
            </div>
            <hr />
            <div class="col-12">
                <div class="exprx--common-block">
                    <div [ngClass]="{'eprx--block__header': !CommentsOpen}">
                        <div [ngClass]="{'eprx--header__heading': !CommentsOpen}">
                            <span *ngIf="!CommentsOpen">Override Credentials</span>
                            <!-- <span *ngIf="CommentsOpen">Comments</span> -->
                        </div>
                    </div>
                    <div [ngClass]="{'eprx--block__content': !CommentsOpen}">
                        <div class="col-sm-12 padding-0 erx--body" *ngIf="!CommentsOpen">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [formGroup]="OverrideLogFG">

                                    <eprx-input  [AutoFocus]="true" [LabelText]="'User Id'" [PlaceHolder]="'User Id'" [ControlName]="'PharmUsername'" [FormGroupName]="OverrideLogFG" [RxSelectId]="'Username'" [IsRequired]="true">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <eprx-input [InputType]="'PASSWORD'" [LabelText]="'Password'" [PlaceHolder]="'Password'" [ControlName]="'PharmPassword'"
                                        [FormGroupName]="OverrideLogFG" [IsRequired]="true">
                                    </eprx-input>
                                    <span class="help--text__danger" *ngIf="warnMessage">{{warnMessage}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12" [formGroup]="OverRideCmntFG" *ngIf="CommentsOpen">
                                <div class="form-group">
                                    <textarea rows="6" cols="20" class="custom--text__area" autofocus formControlName="comment"></textarea>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0" *ngIf="!CommentsOpen">
            <div>
                <button class="hotkey_success" (click)="setOverrideLog()" appShortcutKey [AltKey]="'o'">
               <span>O</span> Ok
                </button>
                <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
        <div class="text-right pull-right col-md-10 padding-0"  *ngIf="CommentsOpen">
                <div>
                    <button class="hotkey_success" (click)="closeCommentModal()" appShortcutKey [AltKey]="'o'">
                   <span>O</span> Ok
                    </button>
                </div>
            </div>
    </div>
</div>
<div *ngIf="IsNotesDisplay" class="eprx-columns-grid">
    <div class="modal-header">
        <h4 class="modal-title" >Override Action</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="modal-body-heading margin_left_1_1rem margin_bottom_1rem" *ngIf="isConsolidated || IsFrmInfo || DrugChangeOnErx">
                Authorization is required for
                <b>"{{privMask | StringSepWithSpaceOnCamCase:'Consolidated'}}" </b> for the selected Rx.
                     Please provide authorization below:
            </div>
            <div class="modal-body-heading" *ngIf="!isConsolidated && !IsFrmInfo && !DrugChangeOnErx">
                User does not have privilege to perform <b> "{{(catId | StringSepWithSpaceOnCamCase)}} - </b>
                <b>{{(privMask | StringSepWithSpaceOnCamCase)}}" </b> action.
                 To override this action, enter the override credentials.
            </div>

            <div class="eprx-row eprx-form-group"> <!---eprx-form-group -> to avoid  curret margin bottom for form-group class-->
                    <div class="eprx-col-6 " >
                        <div class="eprx-card">
                            <div class="eprx-card-header">
                                Override Credentials
                            </div>
                            <div  class="eprx-card-label--data">
                                <eprx-input [AutoFocus]="true" [LabelText]="'User ID'" [PlaceHolder]="'User ID'" [ControlName]="'PharmUsername'"
                                    [FormGroupName]="OverrideLogFG" [RxSelectId]="'Username'" [IsRequired]="true">
                                </eprx-input>
                            </div>
                            <div  class="eprx-card-label--data">
                                <eprx-input [InputType]="'PASSWORD'" [LabelText]="'Password'" [PlaceHolder]="'Password'"
                                    [ControlName]="'PharmPassword'" [FormGroupName]="OverrideLogFG" [IsRequired]="true">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                    <div class="eprx-col-6 pl-0" >
                        <div class="eprx-card">
                           <div class="eprx-row">
                            <div class="eprx-card-header">
                                {{commonlabels?.Notes}}
                            </div>
                           </div>
                           <div class="eprx-row">
                            <div  class="eprx-card-label--data eprx-col-6 px-0">
                                <eprx-input [RxSelectId]="'MainCategory'"  [LabelText]="'Category'" [HasControl]="false" [InputValue]="'Rx Annotation'" [IsDisabled]="true" >
                                </eprx-input>
                           </div>
                           <div  class="eprx-card-label--data eprx-col-6  pr-0">
                            <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true" [SelectOnTab]="true"
                                [LabelText]="'Subcategory'" [PlaceHolder]="'Subcategory'" [IsTitle]="true" 
                                [ControlName]="'NoteCatId'" [FormGroupName]="notesLogFG" [List]="notesLogInfo?.NoteCat" 
                                [BindLabel]="'Name'" [BindValue]="'Id'"  [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'" 
                                [LabelForId]="'Name'" [InformationTool]="'Add a subcategory manually if it is not available in the list.'">
                            </eprx-select>
                           </div>
                            </div>      
                            <div class="eprx-row">  
                                <div class="eprx-card-label--data eprx-col-12 px-0" [formGroup]="notesLogFG">
                                    <angular-editor formControlName="xNote" [config]="editorConfig"></angular-editor>
                                  </div>
                            </div>
                          
                        </div>
                    </div>
            </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="setOverrideLog()" appShortcutKey [AltKey]="'o'">
               <span>O</span> Ok
                </button>
                <button class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
            </div>
        </div>
    </div>
</div>

<ng-template #OverRideW let-c="close" let-d="dismiss" class="el--popup">
        <div class="modal-header">
            <h4 class="modal-title">Error</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal2()">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="eprx--block__content erx--body">
                {{errorMs}}
            </div>
        </div>
        <div class="modal-footer">
            <div class="text-right pull-right col-md-10 padding-0">
                <div>
                    <button class="hotkey_primary" (click)="closeModal2()" appShortcutKey InputKey="o">
                      <b>O</b>  Ok
                    </button>
                </div>
            </div>
        </div>
</ng-template>

<ng-template #Comments let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Comments</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeCommentModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-lg-12" [formGroup]="OverRideCmntFG">
            <div class="form-group">
                <textarea rows="6" cols="20" class="custom--text__area" autofocus formControlName="comment"></textarea>
            </div>
        </div>
        <!-- <textarea rows="3" autofocus cols="20" ></textarea> -->
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="closeCommentModal()" appShortcutKey [AltKey]="'o'">
                  <span>O</span>  Ok
                </button>
            </div>
        </div>
    </div>
</ng-template>

