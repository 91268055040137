/// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    baseUrl: "https://web-app.uat.eprimerx.com/api/",
    reportWriterbaseUrl: "https://reportwriter.stage.eprimerx.com/api/",
    ePrimeRxSignalRUrl: "https://eprimerxsignalr.azurewebsites.net/message",
    interfaceHubUrl: "https://interfacehubapi.stage.eprimerx.com/api/",
    // formsbaseUrl: "https://localhost:7075/api/",
    formsbaseUrl: "https://forms.stage.eprimerx.com/api/",
    documentBaseurl:"https://primeclouddocuments.stage.eprimerx.com/api/",
   // baseUrl: "http://52.170.238.175/api/",
 //  baseUrl: "http://uat.eprimerx.com/api/",
  // baseUrl: "http://uat.eprimerx.com/api/",
    //  baseUrl: "http://20.185.98.80:8787/api/",
   // baseUrl: "https://api.eprimerx.com/api/",
  // baseUrl: "https://preprod-api.eprimerx.com/api/",
    logoUrl: "",
    version: "#Version",
    ImageUrl: "https://web-app.uat.eprimerx.com/assets/dist/",
    baseUrlTranslations: "/",
    translationURL: "./assets/i18n/",
    DrugImage:
        "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/{imagefile}",
    PharmacyLogo:
        "https://eprimerxuat.blob.core.windows.net/mms-pharmacy-logo-uat/{tenantId}/{logofile}",
    SentryURL: "https://6868fedd83e04c15a79c4bb2ec35985a@o95375.ingest.sentry.io/5396659",
    SentryEnvironment: "uat",
    NDCBarcodeImageUrl:
        "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/NDCBarcode.png",
    RxBarcodeImageUrl:
        "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/RxBarcode.png",
    RxRefFillBarcodeImageUrl:
    "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/RxRefillFillBarcode.png",
        DrugBarcodeImageUrl:
        "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/DrugImageSample.png",
    FiledRxImageUrl:
        "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/Label-Filed.png",
    POSBarcodeImageUrl:"https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/POSBarcode.png",
    DWTInstallFolder: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/DWTScanner",
    QzDownloadLink: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/qz-tray-2.1.6+3.exe",
    QzDownloadLinkForMac: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/qz-tray-2.1.6+3.pkg",
    JavaDownloadLink: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/openlogic-openjdk-11.0.16+8-windows-x64.msi",

    JavaDownloadLinkforMac: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/openlogic-openjdk-11.0.16+8-mac-x64.pkg",
    ControlFax: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/ControlFax.pdf",
    SingleFax: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/SingleFaxFormat.pdf",
    TransmissionRejNoCMS: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/TransmissionRejectedFax.pdf",
    TransmissionRejWithCMS: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/TransmissionRejectedFaxWithCMSForm.pdf",
    MultiFaxSingleFormat: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/MultipleFaxSingleRxFormat.pdf",
    MultiPageMultiFaxFormat: "https://eprimerxuat.blob.core.windows.net/mms-drug-images-uat/MutiplePageMultipleFaxFormat.pdf",
    GoogleAnalyticsKey: "",
    FONTAWESOME_NPM_AUTH_TOKEN: "",
    isReleaseNotes : true,
    isGeneratorFIle : true,
    isHoa : true,
    isTimeOffSet : false,
    isReportWriter: true,
    isClinicalForm: true,
    IseRxTransferRequest: true,
    ipAddressUrl : "https://api64.ipify.org?format=json"
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import "zone.js/plugins/zone-error";  // Included with Angular CLI.
