export enum AsyncReportEnum {
   DailyLog = "DailyLog",
   DeferredRx = "FiledRx",
   RxByPharmacist = "RxByPharmacist",
   ControlRx = "ControlRx",
   RxByPrescriber = "RxByPrescriber",
   UnbilledRx = "UnbilledRx",
   RxNotPickedup = "NotPickUpRx",
   RxTransferredReport = "RxTransferredReport",
   SalesTaxSummary = "PharmCustomReports",
   PartialFill = "PartialFill",
   InventoryStatus = "Inventory",
   DrugDispensed = "DrugDispensed",
   DrugDispensedEquivalency = "DrugDispensedEquivalency",
   RefillExpiration = "RefillExpiration",
   RefillDue = "RefillDue",
   PatientMedicationAdherenceReport = "PatientMedicationAdherenceReport",
   priceReport = "Pricereport",
   AuditTrail = "AuditTrail",
   RxsByInsurance = "RxsByInsurance",
   SummaryReports = "SummaryReports",
   ProfitRevenueReports = "ProfitRevenueReports",
   rxlistwithnoattdocs = "rxlistwithnoattdocs",
   rxlistwithattdocs = "rxlistwithattdocs",
   erx = "erx",
   RxFilledSummary = "RXFilledSummary",
   PatientHippaAckReport = "HipaaAck",
   RxPickupLog = "RxPickupLog",
   SignatureClaimsLog = "SignatureClaimsLog",
   pickUpDetails = "pickUpDetails",
   posCopayCollected = "posCopayCollected",
   InventoryReconciliation = "InventoryReconciliation",
   EDI835Remittance = "EDI835Remittance",
   IOUReport = "IOU",
   NotesReport = "Notes",
   ReturnToStock = "ReturnToStock",
   ViewInventory = "ViewInventory",
   ViewEDI835Data = "ViewEDI835Data"

}
export enum AsyncReportNameEnum {
    DailyLog = "Daily Log",
    DeferredRx = "Deferred Rx",
    RxByPharmacist = "Rx By Pharmacist",
    ControlRx = "Control Rx",
    RxByPrescriber = "Rx By Prescriber",
    UnbilledRx = "Unbilled Rx",
    RxNotPickedup = "Rxs Not Pickep-Up",
    RxTransferredReport = "RxTransferredReport",
    SalesTaxSummary = "PharmCustomReports",
    PartialFill = "Partial Fill",
    InventoryStatus = "Inventory Status",
    DrugDispensed = "Drug Dispensed",
    DrugDispensedEquivalency = "Drug Dispensed by Equivalency",
    RefillExpiration = "Refill Expiration",
    RefillDue = "Refill Due",
    PatientMedicationAdherenceReport = "Patient Medication Adherence",
    PriceReport = "Price",
    AuditTrail = "Audit Trail",
    RxsByInsurance = "Rxs By Insurance",
    SummaryReports = "Business Summary",
    ProfitRevenueReports = "Profit Revenue",
    Rxlistwithnoattdocs = "Print Rx list with no attached documents",
    Rxlistwithattdocs = "Print Rx list with attached documents",
    erx = "eRx",
    RxFilledSummary = "RXFilled Summary",
    PatientHippaAck = "Patient Hippa Acknowledgement",
    RxPickupLog = "Print Rx Pickup Log",
    SignatureClaimsLog = "Signature Claims Log",
    PickUpDetails = "Include Rx PickUp Details",
    PosCopayCollected = "POS - Copay Collected",
    InventoryReconciliation = "Inventory Reconciliation",
    EDI835Remittance = "EDI 835 Remittance",
    IOUReport = "IOU",
    NotesReport = "Notes",
    ReturnToStock = "Return to Stock",
    ViewInventory = "View Inventory",
    ViewEDI835Data = "View EDI835 Data"
 }
export enum reportsEnum {
    Reports = 1,
    DMS = 2,
    eRx = 3,
    electronicSignature = 4,
    Rx = 5,
    Inventory = 6,
    EDIReports = 7
}
