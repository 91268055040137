<div class="row exprx--common-block">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class=" box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        <div class=" col-sm-12 padding-0">
                            Personal Information
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Quick Code'" [PlaceHolder]="'Quick Code'"
                                [Title]="'Quick Code'" [ControlName]="'QckCode'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')" [MaxLength]="15"
                                [AutoFocus]="true" [InputType]="'ALPHANUMERIC'"
                                [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_1_8rem">
                            <label class="col-md-12 padding-0">
                                Is Active
                            </label>
                            <eprx-radio [ControlName]="'ActiveStatusId'" [List]="['Yes', 'No']" [ValueList]="[1, 2]"
                                [IDForList]="['ActivePrecY', 'ActivePrecN']" [LabelForId]="" [FormGroupName]="prescriberFG?.get('Person')"
                                >
                            </eprx-radio>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last Name'" [Title]="'Last Name'"
                                [ControlName]="'LastName'" [FormGroupName]="prescriberFG?.get('Person')"
                                [MaxLength]="35" [IsRequired]="true" [MarkAsTouched]="prescriberFG?.get('Person.LastName')?.touched"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'First Name'" [PlaceHolder]="'First Name'" [Title]="'First Name'"
                                [MaxLength]="35" [ControlName]="'FirstName'"
                                [FormGroupName]="prescriberFG?.get('Person')" [IsRequired]="true"
                                [MarkAsTouched]="prescriberFG?.get('Person.FirstName')?.touched"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Telephone# 1'" [PlaceHolder]="'Telephone# 1'"
                                [Title]="'Telephone# 1'" [ControlName]="'xPhone'"
                                [FormGroupName]="prescriberFG.get('Telephone1')" [MaskPattern]="'(000)000-0000'"
                                [ShowMask]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Telephone# 2'" [PlaceHolder]="'Telephone# 2'"
                                [Title]="'Telephone# 2'" [ControlName]="'xPhone'"
                                [FormGroupName]="prescriberFG.get('Telephone2')" [MaskPattern]="'(000)000-0000'"
                                [ShowMask]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Mobile#'" [PlaceHolder]="'Mobile#'"
                                [Title]="'Mobile#'" [ControlName]="'xPhone'"
                                [FormGroupName]="prescriberFG?.get('Mobil')" [MaskPattern]="'(000)000-0000'"
                                [ShowMask]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 no-textTransform">
                            <eprx-input [LabelText]="'Email'" [PlaceHolder]="'Email'" [Title]="'Email'"
                                [ControlName]="'xeMail'" [FormGroupName]="prescriberFG?.get('EMail')"
                                [ErrorDefs]="{ pattern: 'Invalid!' }" [ValidPattern]="regex?.Email"
                                [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Fax#'" [PlaceHolder]="'Fax#'" [Title]="'Fax#'"
                                [ControlName]="'xPhone'" [FormGroupName]="prescriberFG.get('Fax')"
                                [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                            </eprx-input>
                        </div>
                        
                        <div class="col-6">
                            <div class="form-group" [formGroup]="prescriberFG.get('Prescriber')">
                                <label class="control-label full--width">
                                    <span>Remarks</span>
                                    <textarea rows="2" cols="50" formControlName="Remarks" class="custom--text__area"
                                        placeholder="Remarks"></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class=" box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Primary Address Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Address 1'" [PlaceHolder]="'Address 1'" [Title]="'Address 1'"
                                [ControlName]="'AddressLine1'" [FormGroupName]="prescriberFG?.get('Address')"
                                [IsRequired]="true" [MaxLength]="eRxVersion==2 ? 40 : 35" (TriggerOnChange)="onAddressChange($event.target.value,'Address1')"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Address 2'" [PlaceHolder]="'Address 2'" [Title]="'Address 2'"
                                [ControlName]="'AddressLine2'" [FormGroupName]="prescriberFG?.get('Address')"
                                (TriggerOnChange)="onAddressChange($event.target.value,'Address2')"  [MaxLength]="eRxVersion==2 ? 40 : 35">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <app-search-zip [FormGroupName]="prescriberFG?.get('Address')" [ControlName]="'ZipCode'"
                            [zipExtnCntrl]="'Extension'" (OnZipSelected)="patchStateAndCity($event)" [IsRequired]="true"
                                [FormGroupInvalid]="formGroupInvalid"></app-search-zip>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <app-search-state [FormGroupName]="prescriberFG?.get('Address')" [ControlName]="'StateId'"
                                [CityControlName]="'CityId'" [StateSelected]="prescriberState$ | async"
                                [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                                [IsRequired]="true" (OnStateSelected)="
                                    patchStateAndCity($event, 'State')
                                    "></app-search-state>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <app-search-city [FormGroupName]="prescriberFG?.get('Address')" [ControlName]="'CityId'"
                                [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="prescriberCity$ | async"
                                [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }"
                                [FormGroupInvalid]="formGroupInvalid"
                                (OnCitySelected)="patchStateAndCity($event, 'City')"></app-search-city>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Fax#'" [PlaceHolder]="'Fax#'" [Title]="'Fax#'"
                                [ControlName]="'Fax'" [FormGroupName]="prescriberFG.get('Address')"
                                [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [Title]="'Telephone#'"
                                [ControlName]="'Telephone'" [FormGroupName]="prescriberFG.get('Address')"
                                [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-6 drug--price__info padding-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class=" box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        License Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'License#'" [MaxLength]="15" [PlaceHolder]="'License#'"
                                [Title]="'License#'" [ControlName]="'PrescbNum'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')" (blurLicenseEvent)="handleLicenseBlur($event)">
                            </eprx-input>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <eprx-input [RxSelectId]="'PrescNPINUM'" [LabelText]="'NPI#'" [MaxLength]="10" [MinLength]="10" [PlaceHolder]="'NPI#'"
                                [Title]="'NPI#'" [ControlName]="'NPINum'" [MarkAsTouched]="prescriberFG?.get('Person.NPINum')?.touched"
                                [FormGroupName]="prescriberFG?.get('Person')" [ValidPattern]="regex?.NumericExceptZero"
                                [IsRequired]="PrescNPIRequired && npiRequired" [ErrorDefs]="{
                                                required: 'Required',
                                                pattern: 'Npi number should not start with zero',
                                                minlength: 'Npi number must be 10 digits'
                                            }" [FormGroupInvalid]="formGroupInvalid" [MaskPattern]="'9000000000'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                            <div class="col-md-1 insurance--body padding-0">
                                <div class="prescriber-refresh-icon">
                                    <div class="insurance--group--icon padding-0">
                                        <app-prescriber-mms [mmsType]="'NPI'" [prescriberId]="prescriberId" [TextNPI]="TextNPI"
                                            [prescriberInfo]="prescriberInfo" [prescriberFG]="prescriberFG">
                                        </app-prescriber-mms>
                                       <span class="refreshiconstyle">{{TextNPI}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-select [LabelText]="'Prescriber Type'" [PlaceHolder]="'Prescriber Type'"
                                [Title]="'Prescriber Type'" [ControlName]="'PrescCatId'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')" [HasMultiple]="false"
                                [HasBindLabel2]="true" [BindLabel]="'PrescCatCode'" [BindLabel2]="'Name'"
                                [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                                prescdropValues
                                                    ? prescdropValues.PrescriberCat
                                                    : ''"
                                            [Show2Values]="true"
                                            (TriggerSelectValue)="CheckselectedPresciberType($event)">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Medicaid Provider#'" [MaxLength]="15" [PlaceHolder]="'Medicaid Provider'"
                                [Title]="'Medicaid Provider'" [ControlName]="'MedAidNum'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')">
                            </eprx-input>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <eprx-input [LabelText]="'SPI#'" [MaxLength]="13" [PlaceHolder]="'SPI#'"
                                [Title]="'SPI#'" [ControlName]="'SPINum'"
                                [FormGroupName]="prescriberFG?.get('PrescAdds')" [ValidPattern]="regex?.spiRegex"
                                [ErrorDefs]="{
                                                pattern: 'please enter 13 digits'
                                            }" [FormGroupInvalid]="formGroupInvalid" [MaskPattern]="'0000000000000'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                            <div class="col-md-1 insurance--body padding-0">
                                <div class="prescriber-refresh-icon">
                                    <div class="insurance--group--icon padding-0">
                                        <app-prescriber-mms [prescriberId]="prescriberId" [mmsType]="'SPI'"
                                            [prescriberInfo]="prescriberInfo" [prescriberFG]="prescriberFG" [TextSPI]="TextSPI">
                                        </app-prescriber-mms>
                                        <span class="refreshiconstyle">{{TextSPI}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 inline-flex height_2rem displayStateCityZip">
                                <eprx-input [RxSelectId]="'PrescDeano'" [LabelText]="'DEA Reg#'" [MinLength]="9" [PlaceHolder]="'DEA Reg'"
                                    [Title]="'DEA Reg#'" [ControlName]="'DEANum'" [AutoFocus]="focusOnDea"
                                    [FormGroupName]="prescriberFG?.get('Prescriber')"
                                    [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{ minlength: 'Invalid!'}"
                                    [MaskPattern]="'SS0000000'" [RxSelectId]="'DEANumID'">
                                </eprx-input>
                                <label class="zip-Extn"> - </label>
                                </div>
                                <div class='col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2rem pl-0 displayStateCityZip'>
                                    <label class="col-md-12 padding-0"></label>
                                    <eprx-input [RxSelectId]="'PrescDeano'" [LabelText]="''" [MaxLength]="5" [PlaceHolder]="'DEA Suffix'"
                                    [Title]="''" [ControlName]="'DEASuffix'" [AutoFocus]="focusOnDea" [FormGroupName]="prescriberFG?.get('Prescriber')"
                                    [FormGroupInvalid]="formGroupInvalid"
                                    [MaskPattern]="'00000'" [RxSelectId]="'DEANumID'">
                                </eprx-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'XDEA#'" [MaxLength]="9" [PlaceHolder]="'XDEA#'" [Title]="'XDEA#'"
                                [ControlName]="'XDEANum'" [FormGroupName]="prescriberFG?.get('Prescriber')" [ValidPattern]="regex?.deaRegex"
                                [FormGroupInvalid]="formGroupInvalid" [ErrorDefs]="{ pattern: 'Invalid!' }"
                                [MaskPattern]="'SS0000000'" [RxSelectId]="'XDEANumID'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Ins. Restrictions'" [PlaceHolder]="'Ins. Restrictions'"
                                [Title]="'Ins. Restrictions'" [ControlName]="'InsuBlackList'"
                                [FormGroupName]="prescriberFG" [RxSelectId]="'InsRest'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'State Specific ID'" [MaxLength]="15" [PlaceHolder]="'State Specific ID'"
                                [Title]="'State Specific ID'" [ControlName]="'StateSpId'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-input [LabelText]="'Other ID'" [PlaceHolder]="'Other ID'" [Title]="'Other ID'"
                                [ControlName]="'OtherIdNum'" [FormGroupName]="prescriberFG?.get('Prescriber')"
                                [InputType]="'ALPHANUMERIC'" [MaxLength]="15">
                            </eprx-input>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-select [LabelText]="'Prescriber ID Type'" [PlaceHolder]="'Prescriber ID Type'"
                                [Title]="'Prescriber ID Type'" [ControlName]="'PrescIdCatId'"
                                [FormGroupName]="prescriberFG?.get('Prescriber')" [HasMultiple]="false"
                                [HasBindLabel2]="true" [BindLabel]="'PrescIdCatCode'" [BindLabel2]="'Name'"
                                [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                                prescdropValues
                                                    ? prescdropValues.PrescIdCat
                                                    : ''
                                            " [Show2Values]="true">
                            </eprx-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 ">
            <div class=" box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Miscellanous Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  ">
                            <eprx-select [LabelText]="'Primary Speciality'" [PlaceHolder]="'Primary Speciality'"
                                [Title]="'Primary Speciality'" [ControlName]="'SpecialityId'"
                                [FormGroupName]="prescriberFG?.get('PrimarySpeciality')" [HasMultiple]="false" [HasBindLabel2]="true"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'"  [DropdownPosition]="'top'"
                                [LabelForId]="'Id'" [List]="
                                                        prescdropValues
                                                            ? prescdropValues.Speciality
                                                            : ''
                                                    " (TriggerSelectValue)="
                                                        selectedSpecilaity('PrimarySpeciality', $event)
                                                    " [Show2Values]="true">
                            </eprx-select>
                            <eprx-select [LabelText]="'Secondary Speciality'" [PlaceHolder]="'Secondary Speciality'"
                                [Title]="'Secondary Speciality'" [ControlName]="'SpecialityId'" [ShowLabel2]="true"
                                [FormGroupName]="prescriberFG?.get('SecondarySpeciality')" [HasMultiple]="false" [HasBindLabel2]="true"  [DropdownPosition]="'top'"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" (TriggerSelectValue)="
                                                        selectedSpecilaity(
                                                            'SecondarySpeciality'
                                                        )
                                                    " [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                                        prescdropValues
                                                            ? prescdropValues.Speciality
                                                            : ''
                                                    " [Show2Values]="true">
                            </eprx-select>
                            <eprx-select [LabelText]="'Tertiary Speciality'" [PlaceHolder]="'Tertiary Speciality'"
                                [Title]="'Tertiary Speciality'" [ControlName]="'SpecialityId'"
                                [FormGroupName]="prescriberFG?.get('TertiarySpeciality')" [HasMultiple]="false" [HasBindLabel2]="true"  [DropdownPosition]="'top'"
                                [BindLabel]="'Name'" (TriggerSelectValue)="
                                                        selectedSpecilaity('TertiarySpeciality')
                                                    " [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="'Id'"
                                [List]="
                                                        prescdropValues
                                                            ? prescdropValues.Speciality
                                                            : ''
                                                    " [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <eprx-select [LabelText]="'Sales Person'" [PlaceHolder]="'Sales Person'" [Title]="'Sales Person'"
                                [ControlName]="'SalesPersonId'" [FormGroupName]="PrescriberSalesPerson" [HasMultiple]="false"  [DropdownPosition]="'top'"
                                [HasBindLabel2]="true" [BindLabel]="'FirstName'" [BindLabel2]="'LastName'" [ShowLabel2]="true"
                                [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                                        prescdropValues
                                                            ? prescdropValues.PrescriberSalesPerson
                                                            : ''
                                                    " [Show2Values]="true">
                            </eprx-select>
                            <!-- <eprx-select [LabelText]="'Special Program'" [PlaceHolder]="'Special Program'" [ControlName]="'SplProgramId'"
                                [FormGroupName]="SpecialProgram" [HasMultiple]="false" [Title]="'Special Program'" [BindLabel]="'Name'"
                                [BindValue]="'Id'" [LabelForId]="'Id'" [List]="
                                                        systemData ? systemData.SplProgram : ''
                                                    " (keydown.tab)="focusToSave($event)">
                            </eprx-select> -->
                            <eprx-select [LabelText]="'Facility'"   [PlaceHolder]="'Search Facility'" [ControlName]="'FacilityIds'"
                                [FormGroupName]="Prescriber" [HasMultiple]="true" [HasBindLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'"  [DropdownPosition]="'top'"
                                [BindValue]="'Id'" [LabelForId]="''" [List]="facilitiesList"   (TriggerSelectValue)="patchFacilityValues($event)">
                            </eprx-select>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<ng-template #warningInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>{{ warningMsg }}</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" autofocus class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<!-- <app-cancel-pop-up *ngIf="sharedCancel" (IsPopUpClosed)="CancelPopClose($event)"></app-cancel-pop-up> -->
