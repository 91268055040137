import { Injectable } from "@angular/core";
import { CommonService, RxService, UserService } from "../services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { City, ControlRxSubmissionType, DUR, State } from "../models";
import * as moment from "moment";
import { RecallDeleteRxService } from "../services/recall-delete-rx.service";
import { ErxVersions } from 'src/app/models';
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcXlsx from "@grapecity/wijmo.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { RxUtils } from "./rx.util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Verificationsenum } from "../models/Verifications.enum";
import { RxStore } from "../store";
import { CommonWarnorconfirmPopupComponent } from "../modules/shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { MsgConfig } from "../app.messages";
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject } from "rxjs";


@Injectable({
    providedIn: "root"
})

export class CommonUtil {

   billStatusDropdown =  [{"Id" : 0, Name: "All"} , {"Id" : 1, Name: "UnBilled"} , {"Id" : 2, Name: "Billed"}];
   sortByList = [{"Id" : 1, Name: "Date Queued"}  ,
   {"Id" : 3, Name: "Rx"} , {"Id" : 4, Name: "Patient By Name"} , {"Id" : 5, Name: "Patient By Delivery"} , {"Id" : 6, Name: "Drug"}
   , {"Id" : 7, Name: "Prescriber"}, {"Id" : 7, Name: "Facility then Patient"}];
    isChecking: boolean = false;
    acbScore:any;
    DurFG: FormGroup;
    constructor(private _comnSvc: CommonService, private _fb: FormBuilder, private _mdlServ: NgbModal,
        private _userService: UserService, private _recallSer: RecallDeleteRxService,
         private _rxServ: RxService , private _rxUtils: RxUtils, private _rxStore: RxStore ) { 
            this.DurFG = this._fb.group(new DUR());
        }

    async getPrescriberESData(Id) {
        return this._comnSvc.getRxPrescriberDetailsById(Id).toPromise();
    }
    openSessionTimeoutPopup1() {
        const modelRef = this._mdlServ.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
            });
            modelRef.componentInstance.warnMsg = "Session has timed out. Please login to continue."
            modelRef.componentInstance.okButton = true;
            modelRef.componentInstance.IsPopUpClosed.subscribe((resp: any) => {
                modelRef.close();
                window.close();     
            });
    }

    focusById(Id, event, type?: any) {
        if (document.getElementById(Id)) {
            if (event) {
                event.preventDefault();
            }
            if (type && type === "select") {
                const focusId = document.getElementById(Id).getElementsByTagName("input")[0];
                if (focusId) {
                    focusId.focus();
                }
            } else if (type && type === "Page") {
                const data = document.getElementsByClassName("page-item active");
                for (let i = 0; i < data.length; i++) {
                    if (data[i] && data[i]["parentNode"]["parentNode"]["id"] === Id) {
                        const d: any = data[i]["children"][0] as HTMLInputElement;
                        if (d) {
                          d.focus();
                        }
                    }
                }
            } else {
                document.getElementById(Id).focus();
            }
        }
    }

    getRxStatus(prescS, prescRfS, billS) {
        const status = (prescRfS === 4 || prescS === 4) ? "T" :
            (prescRfS === 2 || prescS === 2 || billS === 6) ? "D" :
                (prescRfS === 3 || prescS === 3) &&
                    billS === 2 ? "BI" :
                    (billS === 2 && prescRfS !== 4 && prescS !== 2
                        && prescS !== 4 && prescRfS !== 2) ? "B" :
                        ((prescRfS === 3 || prescS === 3) &&
                            billS === 1) ? "UI" : "U";
        return status;
    }

    getRxTotalAmnt(data) {
        const totalAmnt = (data.Price ? data.Price : 0) +
            (data.ProfFee
                ? data.ProfFee
                : 0) +
            (data.OtherAmnt
                ? data.OtherAmnt
                : 0) -
            (data.Discount
                ? data.Discount
                : 0) +
            (data.CoPay ? data.CoPay : 0);
        return totalAmnt;
    }

    generateFA(key: string, values: any[], formGroupName) {
        let extraCostFGs = [];
        if (values) {
            extraCostFGs = values.map(val => this._fb.group(val));
        }
        const extraCostFormArray = this._fb.array(extraCostFGs);
        formGroupName.setControl(key, extraCostFormArray);
    }

    telphoneFormats(telephone) {
        const a = telephone.replace(/[\])}[{(]/g, '');
        return a.replace(/-/, '');
    }

    async patchStateAndCity(data, formGroup, type?: any, frm?: any) {
        const stateCityCntrlNames = {
            State: { StateId: "Id", StateName: "Name", CityId: "CityId" },
            City: { StateId: "StateId", CityName: "Name", CityId: "Id" }
        };
        let citySelected: City;
        let stateSelected: State;
        citySelected = new City();
        stateSelected = (!type || type === "State") ? new State() : stateSelected;
        const addressFG: any =  formGroup && formGroup.controls ? formGroup.controls[frm ? "Addresses" : "Address"] : [];
        await Object.keys(stateCityCntrlNames).map(subType => {
            Object.keys(stateCityCntrlNames[subType]).map(subCntrl => {
                const dataCntrl = stateCityCntrlNames[subType][subCntrl];
                if (!type) {
                    if (addressFG && addressFG.controls) {
                    addressFG.controls[subCntrl].patchValue(data ? data[subCntrl] : null);
                    }
                } else if (type === subType) {
                    if (addressFG && addressFG.controls) {
                    addressFG.controls[subCntrl].patchValue(data ? data[dataCntrl] : null);
                    }
                }
                if (subType === "City" && (!type || type === "City")) {
                    citySelected[dataCntrl] = data ? !type ? data[subCntrl] : data[dataCntrl] : null;
                } else if (subType === "State" && (!type || type === "State")) {
                    stateSelected[dataCntrl] = data ? !type ? data[subCntrl] : data[dataCntrl] : null;
                }
            });
        });
        return {state: stateSelected, city: citySelected};
    }
    selectedRxStatus(billSearchArray: any = []) {
        if (billSearchArray && billSearchArray.length > 0) {
                if (billSearchArray[billSearchArray.length - 1] === 0) {
                  return [0];
                } else {
                 return   (billSearchArray.filter((ele: any) => {
                            return ele !== 0;
                    }));
                }
        }
    }
    refillQueueShow(refillQueueShow: any = []) {
        if (refillQueueShow && refillQueueShow.length > 0) {
            if (refillQueueShow[refillQueueShow.length - 1] === "All") {
              return ["All"];
            } else {
             return   (refillQueueShow.filter((ele: any) => {
                        return ele !== "All";
                }));
            }
    }
    }
    filterAllinList(list: any = []) {
        if (list && list.length > 0) {
                if (list[list.length - 1] === 'all') {
                  return ['all'];
                } else {
                 return   (list.filter((ele: any) => {
                            return ele !== 'all';
                    }));
                }
        }
    }
    getPatientAge(PatDob) {
    let age;
        if (PatDob) {
            const dob = moment(PatDob);
            const years = moment().diff(dob, "year");
            dob.add(years, "years");
            const months = moment().diff(dob, "months");
            dob.add(months, "months");
            age = years + " Yrs, " + months + " M";
        } else {
            age = null;
        }
    return age;
    }
    getNDCFormat(drugNdc) {
       return  drugNdc ? (drugNdc.substr(0, 5)) + "-" + (drugNdc.substr(5, 4)) + "-" + (drugNdc.substr(9, 11)) : null;
    }
    getPhoneFormat(number) {
        if (number) {
        return ( ((number && number[0] === '(') ?  "" : "(") + (number.substr(0, 3)) +  ((number && number[4] === ')') ?  "" : ")") + (number.substr(3, 3)) + "-" + (number.substr(6, 4)));
        } else {
            return "";
        }
    }
    getPhoneFormatForWrongFax(number) {
        if (number) {
        return ( ((number && number[0] === '(') ?  "" : "(") + (number.substr(0, 3)) +  ((number && number[4] === ')') ?  "" : ")") + (number.substr(3, 3)) + "-" + (number.substr(6, 5)));
        } else {
            return "";
        }
    }
    getErxVersion() {
        let eRxVersion:any;
        const versionsetting = this._comnSvc.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
        eRxVersion  = (versionsetting == 2)  ? ErxVersions.NewerVersion : ErxVersions.OldVersion;
        return eRxVersion;
    }
    selectedInsuranceType(insuranceList: any = []) {
        if (insuranceList && insuranceList.length > 0) {
                if (insuranceList[insuranceList.length - 1] === "All") {
                  return ["All"];
                } else {
                 return   (insuranceList.filter((ele: any) => {
                            return ele !== "All";
                    }));
                }
        }
    }
    checkIsGreenBilled(rxInfo, systemData, forCmpIng?:boolean) {
        let hasPayRecrds = false;
        let isBilled = false;
        if (rxInfo && rxInfo.RxPay && rxInfo.RxPay.length > 0) {
            hasPayRecrds = rxInfo.RxPay.some(pay => (pay.RecType.trim().toUpperCase() === "P" || pay.RecType.trim().toUpperCase() === "C" ||
             pay.RecType.trim().toUpperCase() === "D") && (forCmpIng || ((pay.CoverageCD + 1) === (+rxInfo.RefillInsu.InsuPriId))));
        }
        if (rxInfo.Patient && rxInfo.Patient.insurance && rxInfo.Patient.insurance.length) {
            const primaryInsu = rxInfo.Patient.insurance.find(v => v["insuranceid"] === rxInfo.RefillInsu.InsuId);
            if (primaryInsu && (primaryInsu["IsCash"] === true || ( systemData && systemData.DefaultValuese &&
                systemData.DefaultValuese.length && primaryInsu["insurerid"] === systemData["DefaultValuese"][0]["CashInsurerId"]))) {
                isBilled =  true;
            } else {
                isBilled = hasPayRecrds ? true : false;
            }
        }
        return isBilled;
    }

    async saveWjGridDet(data) {
        data["UserId"] = this._userService.getToken("UserId")
            ? +this._userService.getToken("UserId") : null;
        await this._comnSvc.saveWJGridDet(data).toPromise();
        this._comnSvc.getWjGridSavedDet();
    }

    updateDashbordStatus() {
        const defaultDaysForDateRange =
            +this._comnSvc.getSetttingValue("WorkFlowSettings", "Default_days_for_Dashboard_date_range") || 7;
        const dateGrp = {
            StartDate: moment().subtract(defaultDaysForDateRange, "days").format("MM/DD/YYYY"),
            EndDate: moment().format("MM/DD/YYYY")
        };
        this._comnSvc.getSideNavCounts(dateGrp);
    }

    async checkCanAssignNewRxFrFile(rxInfo) {
        let result = false;
        let isContrReSub = false;
        if ((rxInfo.Drug.drugclass > 1 && rxInfo.Drug.drugclass < 6) ||
            (await this._recallSer.CheckIOCDrugorNot(rxInfo.Drug.ndc, rxInfo.Patient.state).toPromise())) {
            if (rxInfo.PrescReFill.SubmissionStatusId === ControlRxSubmissionType.Void) {
                result = true;
                isContrReSub = true;
            } else {
                if (rxInfo.PrescReFill.ReFillNum === 0 && rxInfo.PrescReFill.PartialFillNo === 1) {
                    result = this._comnSvc.getSetttingValue("RxSettings", "ASSIGN_RXNO_FILED") === "1" ? true : false;
                }
            }
        } else {
            if (rxInfo.PrescReFill.ReFillNum === 0 && rxInfo.PrescReFill.PartialFillNo === 1) {
                result = this._comnSvc.getSetttingValue("RxSettings", "ASSIGN_RXNO_FILED") === "1" ? true : false;
            }
        }
        return {filed: result, control: isContrReSub};
    }

    setDefaultValues(FG,type?) {
        let today = moment().format("MM/DD/YYYY");
        if(type && type == "autoSubmissionFG"){
            FG.patchValue({
                CntrlFromDate:'d',
                CntrlToDate:'d',
                CntrlFromDueDateDays:0,
                CntrlToDueDateDays:0,
            })
        }else if(type && type == "autoSubmissionWithNoParamsFG"){
            FG.patchValue({
                CntrlFromDate:'d',
                CntrlToDate:'d',
                CntrlFromDueDateDays:0,
                CntrlToDueDateDays:0,
                FromDt:today,
                ToDt:today,
                IncRxNotSubmitted:true,
                IncRxResubmission:true,
                FrmTime:"0:0",
                ToTime:"23:59",
            })
        } else if (type && type === "refReqParams") {
            FG.patchValue({
                RqFromDate: "d",
                RqToDate : "d",
                FromDate: today,
                ToDate: today,
                RqFromDueDateDays: 0,
                RqToDueDateDays: 0,
                PatientCategory: "Patient Category",
                TherapeuticOrUseDefined: true,
                PrescExpDays: 0,
                IsAutoSfax: true,
                IsPrescribersIncluded: true,
                Drugclass: [0],
                FaxFormat: true,
                IsIncInRefillRem: false,
                ShowMaintenanceDrugsOnly: false,
                PrescWithRefOnly:  false,
                ExcludeEqvDeferredRxs : true
            });
        } else {
            const defaultPatchNames = ["IncludeFacilityPatients", "ShowMaintenanceDrugsOnly",
            "ShowExpiring", "PickupDate", "ExcludeRefillRemainder", "ShowExpiredRxOnly", "ConsumptionThreshold"];
            const defaultPatchValueTrueNames = ["BasedOnEquiDrugs", "ShowExpired", "IsPartialFill"];
            defaultPatchNames.map(control => {
                FG.controls[control].patchValue(false);
            });
            defaultPatchValueTrueNames.map(control => {
                FG.controls[control].patchValue(true);
            });
            FG.controls["AllForSinceDate"].patchValue('d');
            FG.controls["AllForFromDueDate"].patchValue('d');
            FG.controls["AllForToDueDate"].patchValue('d');
            FG.controls["SinceDateDays"].patchValue(0);
            FG.controls["FromDueDateDays"].patchValue(0);
            FG.controls["ToDueDateDays"].patchValue(0);
            FG.controls["RefillFillDate"].patchValue(today);
            FG.controls["refillsDueFromDate"].patchValue(today);
            FG.controls["refillsDueToDate"].patchValue(today);
            FG.controls["drugClass"].patchValue("All");
            FG.controls["sortBy"].patchValue("Rx");
            FG.controls["RxRefillReminder"].patchValue("All");
            FG.controls["PatientRefillReminder"].patchValue("All");
            FG.controls["DocumentType"].patchValue("compact");
            FG.controls["PatientCategory"].patchValue("Patient Category");
            FG.controls["billSearch"].patchValue([0]);
            FG.patchValue({
              IncludeFiledRx: false,
              FiledRxs: "all",
              ShowCompactReport: false,
              ShowIns: false,
              ShowProfit: false
            });
        }
      }
      calculateColumnWidth(value, change,noOfColums) {
        let result = value;
        if (change && change !== 1662 && change !== NaN) {
            result = value + change / noOfColums;
        }
        return result;
    }
    setDrugClass(drugclassType) {
        let drugclass;
        if (drugclassType === "All") {
            drugclass = "1";
        } else if (drugclassType === "Class 2") {
            drugclass = "2";
        } else if (drugclassType === "Class 3") {
            drugclass = "3";
        } else if (drugclassType === "Class 4") {
            drugclass = "4";
        } else if (drugclassType === "Class 5") {
            drugclass  = "5";
        } else if (drugclassType === "Exclude Controls") {
            drugclass = "7";
        } else if (drugclassType  === "All Control Drugs(2-5)") {
            drugclass = "6";
        } else if (drugclassType === "Class 1") {
            drugclass = "8";
        }
        return drugclass;
    }
    checkRxvBeforeTransiomisson(totalRxInfo) {
        const withoutRxVerification = [], unRxVerifiedRecordsForDisplay  = [];
        // const orginal = parseFloat(this._comnSvc.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_Original_Rx_Only"));
       const rxVerReqBrTr = parseInt(this._comnSvc.getSetttingValue("WorkFlowSettings",
           "Rx_Verification_Required_Before_Transmission"), 0);
        const reqRxVerfStng = parseInt(this._comnSvc.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        // const workFlwPatStng = parseInt(this._comnSvc.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
           if (reqRxVerfStng && rxVerReqBrTr &&  totalRxInfo && totalRxInfo.length) {
               /*if (workFlwPatStng) {
                    totalRxInfo.map(item => {
                        if (!(item["rph1VerificationStatus"] && item["rph1VerificationStatus"].toLowerCase() === "r")
                        && ((orginal && item["PrescReFill"]["ReFillNum"] === 0) || !orginal) && item["Patient"] && item["Patient"]["IsWorkflowPatient"]) {
                            withoutRxVerification.push(item);
                        }
                    });
               } else {*/
                    totalRxInfo.map(item => {
                        if (!(item["rph1VerificationStatus"] && item["rph1VerificationStatus"].trim().toLowerCase() === "r")) {
                            withoutRxVerification.push(item);
                        }
                    });
                // }
            }
        if (withoutRxVerification && withoutRxVerification.length) {
            withoutRxVerification.map((item: any) => {
                if (item && item.Prescription && item.Prescription.PrescNum) {
                    unRxVerifiedRecordsForDisplay.push(item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum +
                     (this._comnSvc.checkIsPartialFill(item.PrescReFill, item.Prescription) ? "-" + item.PrescReFill.PartialFillNo : " "));
                }
            });
        }
        return {withoutRxVerification : withoutRxVerification, unRxVerifiedRecordsForDisplay: unRxVerifiedRecordsForDisplay};
    }
      calculateTotWidth(columns) {
        let tWidth = 0;
        if (columns && columns.length) {
            const noOfCols = columns.filter(v => v.isVisible === true);
            if (noOfCols && noOfCols.length) {
                noOfCols.map(c => tWidth = tWidth + (c.width !== "*" ? c.width : 0));
            }
        }
        return tWidth;
    }


    checkAndStore340BFTabs(StngVal?: any) {
        this._comnSvc.store340BValForTabs(this._comnSvc.checkIs340BPhar(StngVal));
    }
    getRphStatus(rphVerificationStatus,FollowUpStatus?, rphVerfiyList?: any) {
        let rStaus : any;
        if(rphVerificationStatus && rphVerificationStatus.trim().toLowerCase() === "v" ) {
            rStaus = rphVerfiyList ? ( !rphVerfiyList.IsReverified ? Verificationsenum.verified : Verificationsenum.reverified) : Verificationsenum.verified;
        } else if(rphVerificationStatus && rphVerificationStatus.trim().toLowerCase() === "rv") {
            rStaus = "Re-Verify";
        } else if(FollowUpStatus === "F" ) {
            rStaus = "Follow up";
        }  else {
            rStaus = "NOT YET VERIFIED";
        }
        return rStaus;
    }
    getRXStatus(rxVerificationStatus, rxVerfiyList?: any) {
        let rxStaus = "";
        if (rxVerificationStatus && rxVerificationStatus.trim().toLowerCase() === "r") {
            rxStaus = rxVerfiyList ? (!rxVerfiyList.IsReverified ? Verificationsenum.verified : Verificationsenum.reverified) : Verificationsenum.verified;
        } else if (rxVerificationStatus && rxVerificationStatus.trim().toLowerCase() === "rv") {
            rxStaus = "Re-Verify";
        } else {
            rxStaus = "NOT YET VERIFIED";
        }
        return rxStaus;
    }
    getDrugStatus(rxVerificationStatus, dpvVerfList?:any) {
        let rxStaus : any;
        if(rxVerificationStatus && rxVerificationStatus.trim().toLowerCase() === "p") {
            rxStaus = dpvVerfList ?( !dpvVerfList.IsReverified ? Verificationsenum.verified : Verificationsenum.reverified) : Verificationsenum.verified;
        } else if(rxVerificationStatus && rxVerificationStatus.trim().toLowerCase() === "rv") {
            rxStaus = "Re-Verify";
        } else {
            rxStaus = "NOT YET VERIFIED";
        }
        return rxStaus;
    }

    exportFormatItem(args: wjcGridXlsx.XlsxFormatItemEventArgs) {
        args.xlsxCell.formula = null;
        const textcontent = (args.xlsxCell.value || args.xlsxCell.value === 0) ? args.xlsxCell.value.toString() : " ";
        if (textcontent) {
        args.xlsxCell.value = (textcontent + " ").toUpperCase();
        args.xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
        }
    }

    checkPayRecExistOrNotForSelcRx(rxPay) {
        if (rxPay && rxPay.length) {
            const rxPayRecords = rxPay.filter((v: any) => v.RecType !== "R");
            return ((rxPayRecords && rxPayRecords.length) ?  true : false);
        } else {
            return false;
        }
    }

    getFilteredData(selectedValue) {
        let filterValue: string;
            if (selectedValue === "All") {
                filterValue = null;
            } else if (selectedValue === "Partial Fills only") {
                filterValue = "Partial";
            } else if (selectedValue === "Non-Partial Fills Only") {
                filterValue = "NonPartial";
            } else {
                filterValue = selectedValue;
            }
            return filterValue;
    }

      selectedState(stateList: any) {
        if (stateList && stateList.length > 0) {
                if (stateList[stateList.length - 1] === "All") {
                  return ["All"];
                } else {
                 return   (stateList.filter((ele: any) => {
                            return ele !== "All";
                    }));
                }
        } else {
            return null;
        }
    }
    rxStatusForTransferInOut(rxTransferDet) {
        let rxStatus = "";
        if (rxTransferDet && rxTransferDet.Rxtransfer) {
            const rxData = rxTransferDet.Rxtransfer;
            if ((rxData.RxStatus === 1 || rxData.StatusId === 1) && rxData.RxStatusId === 1) {
                rxStatus = "unbilled";
            } else if ((rxData.RxStatus === 1 || rxData.StatusId === 1) && rxData.RxStatusId === 2) {
               rxStatus = "billed";
            } else if (rxData.RxStatus === 2 || rxData.StatusId === 2) {
                rxStatus = "filed";
            } else  {
                rxStatus = "transferred";
            }
        }
        return rxStatus;
    }

    checkFileFormats(dataType) {
        const formatArrTypes = ["application/pdf", "application/png", "application/jpeg", "application/jpg", "application/gif", "application/x-xwindowdump"];
        return formatArrTypes.includes(dataType);
    }
    getRxStatusinSignleCharacter(rxInfo) {
        if(rxInfo && rxInfo.PrescReFill && rxInfo.Prescription){
            return (rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4) ? "T" :
            (rxInfo.PrescReFill.StatusId === 2 || rxInfo.Prescription.RxStatusId === 2 || rxInfo.RxBill.StatusId === 6) ? "D" :
                (rxInfo.PrescReFill.StatusId === 3
                    || rxInfo.Prescription.RxStatusId === 3) && rxInfo.RxBill.StatusId === 2 ? "BI" :
                    (rxInfo.RxBill.StatusId === 2 && rxInfo.PrescReFill.StatusId !== 4
                        && rxInfo.Prescription.RxStatusId !== 2) ? "B" :
                        ((rxInfo.PrescReFill.StatusId === 3 || rxInfo.Prescription.RxStatusId === 3) &&
                            rxInfo.RxBill.StatusId === 1) ? "UI" : "U";
        }
    }

    getRxStatusForDisplay(rxInfo) {
       const systemData =  this._comnSvc.systemData$["source"]["value"]
       const IsBilled = this.checkIsGreenBilled(rxInfo, systemData);
        if (rxInfo.RxBill.StatusId === 1) {
            return  {status: "Unbilled", bgColor:IsBilled?'#bd362f':'#bd362f',colorText: '#FFF'};
        } else if (rxInfo.RxBill.StatusId === 2) {
            return{status: "Billed", bgColor:IsBilled?'darkgreen':'#bd362f' ,colorText: '#FFF'};
        } else if (rxInfo.RxBill.StatusId === 3 || rxInfo.RxBill.StatusId === 4) {
            return {status: "Transferred", bgColor:'',colorText: '#000'};
        } else {
            return  {status: "Deferred", bgColor:'#0000FF',colorText: '#FFF'};
        }
    }
  

    async checkForPriorApprvlMandatoryFields(rxInfo, insuranceInfolatest?: any, fromYes?: boolean, fromTransmit?: boolean, transmResp?: any) {
        const dataToPost = {};
        let rejPayRec =  null;
        if (!fromTransmit && rxInfo && rxInfo.RxPay && rxInfo.RxPay.length) {
            rejPayRec = rxInfo.RxPay[rxInfo.RxPay.length - 1];
        }
        dataToPost["rejection_code"] = !fromTransmit ? (rejPayRec && rejPayRec.REJCodes ?  rejPayRec.REJCodes  : "") : (transmResp && transmResp.nCPDPRecv && transmResp.nCPDPRecv.RejCodes ?  transmResp.nCPDPRecv.RejCodes  : "");
        dataToPost["rejection_message"] = !fromTransmit ? (rejPayRec && rejPayRec.RejectReason ? rejPayRec.RejectReason : "") : (transmResp && transmResp.transmissionDetails.RejectInfo ? transmResp.transmissionDetails.RejectInfo : "");
        dataToPost["service_provider_id"] = insuranceInfolatest && insuranceInfolatest.InsuPlan && insuranceInfolatest.InsuPlan.PharmacyNum ? insuranceInfolatest.InsuPlan.PharmacyNum : "";
        dataToPost["service_provider_id_qualifier"] = insuranceInfolatest && insuranceInfolatest.InsuPlan && insuranceInfolatest.InsuPlan.InsuPlanIdQualifId ?  insuranceInfolatest.InsuPlan.InsuPlanIdQualifId :"";
        dataToPost["product_service_id"] = rxInfo.Drug.ndc;
        dataToPost["product_service_id_qualifier"] = rxInfo.Drug.ndcqualiId;
        dataToPost["prescriber_fax"] = rxInfo.Prescriber.faxnumber;
        dataToPost["prescRefillId"] = rxInfo.PrescReFill.Id;
        dataToPost["RxData"] = rxInfo;
        dataToPost["TransType"] = "B1";
        const billAs = rxInfo.RefillInsu.InsuPriId;
        dataToPost["BillAs"] = billAs === 2 ? "S" : billAs === 3 ? "T" : "P";
        dataToPost["ins_code"] = (rxInfo.insuranceInfo && rxInfo.insuranceInfo.insuCarrier && rxInfo.insuranceInfo.insuCarrier.InsurerCode) ?
                rxInfo.insuranceInfo.insuCarrier.InsurerCode : null;
        dataToPost["InsuId"] = (rxInfo && rxInfo.RefillInsu && rxInfo.RefillInsu.InsuId) ? rxInfo.RefillInsu.InsuId : null;
        // if (
        //     rxInfo.insuranceInfo &&
        //     rxInfo.insuranceInfo.insuCarrier &&
        //     rxInfo.insuranceInfo.insuCarrier.InsurerCode
        // ) {
        //     dataToPost["ins_code"] =
        //         rxInfo.insuranceInfo.insuCarrier.InsurerCode;
        //     dataToPost["InsuId"] = null;
        // } else {
        //     dataToPost["InsuId"] = rxInfo.RefillInsu.InsuId;
        //     dataToPost["ins_code"] = null;
        // }
        if (!fromYes) {
            const result = await this._rxServ.getMandatoryCheckPriorApproval(dataToPost).toPromise();
            return result;
        } else {
            const result = await this._rxServ.getPriorApprovalUrl(dataToPost).toPromise();
            return {
               "response": result,
               "dataToPost": dataToPost
            };
        }
    }

    checkForPACMMButton(rxInfo) {
        let insuranceList = null;
        let payRecrds = null;
        if (rxInfo) {
            if (rxInfo.Patient && rxInfo.Patient.insurance && rxInfo.Patient.insurance.length) {
                insuranceList = rxInfo.Patient.insurance.find((item: any) => {
                    return item.insuranceid === rxInfo.RefillInsu.InsuId;
                });
            }
            if (rxInfo.RxPay && rxInfo.RxPay.length && insuranceList && insuranceList.InsurerCode) {
                payRecrds = rxInfo.RxPay.filter((item: any) => {
                    return (item.Ins_Code.trim() === insuranceList.InsurerCode.trim());
                });
            }
            const result = this.checkPayRecExistOrNotForSelcRx(payRecrds);
            return result;
        }
    }
    focusOnFiled(focusId) {
        if(focusId) {
            const element: any =  document.getElementById(focusId);
             if(element)
                   element.focus();
         }
    }

    exportItem(args: wjcGridXlsx.XlsxFormatItemEventArgs) {
        args.xlsxCell.formula = null;
        const textcontent = (args.xlsxCell.value || args.xlsxCell.value === 0) ? args.xlsxCell.value.toString() : " ";
        if (textcontent) {
        args.xlsxCell.value = (args.col !== 8) ? (textcontent + " ").toUpperCase() : textcontent + " ";
        args.xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
        }
    }

    checkDateTime(value) {
        if ((moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid())
            || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid())) {
            return true;
        } else {
            return false;
        }
    }

    getDawName(dawId) {
        if (dawId == 3) {
            return "NO DAW(0)";
        } else if (dawId == 4) {
            return "PHYSICIAN DAW(1)";
        } else if (dawId == 5) {
            return "PATIENT DAW(2)";
        } else if (dawId == 6) {
            return "PHARMACY DAW(3)";
        } else if (dawId == 7) {
            return "SUBSTITUTION ALLOWED - GENERIC DRUG NOT IN STOCK(4)";
        } else if (dawId == 8) {
            return "SUBSTITUTION ALLOWED – BRAND DRUG DISPENSED AS GENERIC(5)";
        } else if (dawId == 9) {
            return "OVERRIDE (6)";
        } else if (dawId == 10) {
            return "SUBSTITUTION NOT ALLOWED. BRAND DRUG MANDATED BY LAW(7)";
        } else if (dawId = 11) {
            return "SUBSTUTUTION ALLOWED – GENERIC DRUG NOT AVAILABLE IN MARKET";
        } else if (dawId == 12) {
            return "OTHER(9)";
        }
    }

    getDawValue(dawId) {
        if (dawId == 4) return "Y";
        else return "N";
    }

    checkDateTimeFields(val) {
        if (val && ((moment(val, "MM/DD/YYYY", true).isValid()) || (moment(val, "MM-dd-yyyy", true).isValid()) || (moment(val, "yyyy-MM-dd", true).isValid()) ||
        (moment(val, "YYYY-MM-DDTHH:mm:ss", true).isValid()) || (moment(val, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid())
            || (moment(val, "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ", true).isValid()) || (moment(val, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()))) {
                return true;
        } else {
            return false;
        }
    }

    getDrugClasses(drugClassArray) {
        if (drugClassArray && drugClassArray.length) {
            if (drugClassArray[drugClassArray.length - 1] === 0) {
              return [0];
            } else if (drugClassArray[drugClassArray.length - 1] === 6) {
                return [6];
            } else if (drugClassArray[drugClassArray.length - 1] === 7) {
                return [7];
            } else {
                const newArr = [];
                drugClassArray.filter((ele: any) => {
                    if (ele !== 0 && ele !== 6 && ele !== 7) {
                            newArr.push(ele);
                    }
                });
                return newArr;
            }
        }
    }
    /*getDeliveryMethodId(rxInfo){ // not in use
        if(rxInfo.Patient.PatientInfo.DeliveryModeId && rxInfo.Patient.PatientInfo.DeliveryModeId !== 5){
            return rxInfo.Patient.PatientInfo.DeliveryModeId;
        } else {
           return  +this._comnSvc.getSetttingValue("RxSettings", "DEF_DELIVERY_METHOD") ?
                        +this._comnSvc.getSetttingValue("RxSettings", "DEF_DELIVERY_METHOD") : this._rxUtils.getDelModeDefVal();
        }
    }*/
    modalPopUpOpen(modal:any){
       return  this._mdlServ.open(modal.modalPopupRef, {
            backdrop: false,
            keyboard: false, windowClass: modal.windowClass,
            centered:true
        });
    }
    getlatestRefillData(rxrefDetails){
        let refDet = rxrefDetails["Refdetail"]["Refilldetails"];
        if (refDet && refDet.length) {
            const data = refDet.sort((a: any, b: any) =>
                (a.PrescReFill.ReFillNum > b.PrescReFill.ReFillNum) || ((a.PrescReFill.ReFillNum === b.PrescReFill.ReFillNum) &&
                    (a.PrescReFill.PartialFillNo > b.PrescReFill.PartialFillNo)) ? -1 : 1);
             return data;
        }
    }
    getRxType(typeofRx:number,MessageType:string) {
        let rxType:string = '';
        if(typeofRx === 2) {
            rxType = "Deferred Rx";
        } else if(typeofRx === 1) {
            rxType = "Refill Due";
        } else if(typeofRx === 3 && MessageType) {
            if(MessageType  === 'RxChangeResponse') {
                rxType = "Rx Change Response";
            } else if(MessageType === 'NewRx') {
                rxType =  "New Rx";
            } else if(MessageType === 'REFRES') {
                rxType =  "Refill Response";
            } else {
                rxType =  MessageType;
            }
        }
        return rxType;
    }
    focusToFirst(event,id) {
        if (document.getElementById(id)) {
          event.preventDefault();
        //   setTimeout(() => {
            document.getElementById(id).focus();
        //   }, 10);
    
        }
      }
      //Check wheather ACB score need to show or not
      showACBScore(Obj) {
        const dob = moment(Obj.patientData.dob);
        const years = moment().diff(dob, "year");
        if (Obj.isPrmptHighRsk && ((years > Obj.geriatricAge) ||  (years < Obj.youthUnderAge) ||
        (Obj.pregantPatient && Obj.patientData && Obj.patientData.gender === "Female"
         && Obj.patientData.PatientInfo && Obj.patientData.PatientInfo.PregnancyCdId === 3))) {
            return true;
      }
      return false;
    }

    storeAcbScore(val){
        this.acbScore = val
    }
    getAcbScore(){
        return this.acbScore
    }

    async storedefaultDrugInsPref(rxFG, frmDur?, frmothamnt?) {
        let subClarfId: any;
        const drugInsRestr = this._rxStore.drugInsRestr$["source"]["value"];
        const refInsuData = rxFG?.value?.Patient?.insurance && rxFG.value["Patient"]["insurance"]?.length ? rxFG.value["Patient"]["insurance"].find((item: any) =>
        item.insuranceid === rxFG.value["RefillInsu"]["InsuId"]) : null;
        let insPrefData = drugInsRestr?.length ? drugInsRestr.find((item: any) => item.InsurId === refInsuData?.Id) : null;
        if (!insPrefData) {
            insPrefData = drugInsRestr?.length ? drugInsRestr.find((item: any) => item.IsAllInsu) : null;
        }
        const INS = await this._rxUtils.getfindAndInsuDet(rxFG.value["Patient"], null,
                    rxFG.value.RefillInsu.InsuId);
        if (INS && INS.InsuTransmission) {
            console.log(INS.InsuTransmission, "Trnams");
        }
        const miscInfo = this._rxStore.rxMiscInfo$["source"]["value"];
        console.log(miscInfo, "miscInfo");
        if (miscInfo && miscInfo.rxExtra) {
            subClarfId = miscInfo.rxExtra.SubClarifId1 ? miscInfo.rxExtra.SubClarifId1 : (miscInfo.rxExtra.SubClarifId2 ? miscInfo.rxExtra.SubClarifId2 : miscInfo.rxExtra.SubClarifId3);
        }
        rxFG.controls["SubClarifIdComb"].patchValue(insPrefData ? insPrefData.SubClarifId : ((INS &&  INS?.InsuTransmission?.SubClarifId) ? INS.InsuTransmission.SubClarifId :
        subClarfId));
        const fg = rxFG.controls["RxOtherAmount"] as FormGroup;
        const otherAmount = rxFG.value.RxOtherAmount;
        if (insPrefData) { 
            if (!frmDur) {
                fg.controls["Incentive"].patchValue(!(+otherAmount["Incentive"])  ?insPrefData.Incentive : otherAmount["Incentive"]);
                fg.controls["CostBasisCatId"].patchValue(!otherAmount["CostBasisCatId"] ? insPrefData.CostBasisId : otherAmount["CostBasisCatId"]);
            }
            if(!frmothamnt && rxFG.controls["rxType"].value !== "rf") {
                this.patchingDur(insPrefData, true);
                const durDataNewRxFlow = [this.DurFG.value];
                this._rxStore.storeDurNewRxFlow(durDataNewRxFlow);
            }
        } else {
            fg.controls["Incentive"].patchValue("0.00");
            fg.controls["CostBasisCatId"].patchValue(null);
            this._rxStore.storeDurNewRxFlow(null);
        }
    }
    patchingDur(val, frmInsPref?){
        this.DurFG.patchValue({
            ServReasonId: val && val["ServReasonId"] ? val["ServReasonId"] : null,
            ProfServId: val&&  val["ProfServId"] ? val["ProfServId"] : null,
            ServResultId: val && val["ServResultId"] ? val["ServResultId"] : null,
            EffortLvlId: val && val["EffortLvlId"] ? val["EffortLvlId"] : null,
            CoAgentIdType : val && val["CoAgentIdType"] ? val["CoAgentIdType"] : null,
            CoAgentNum: val && val["CoAgentNum"] ? val["CoAgentNum"] : null,
            ClinicalSignfId: val &&  val["ClinicalSignfId"] ? val["ClinicalSignfId"] : null,
            PhComment: val ? (frmInsPref ? (val["PhComment"] ? val["PhComment"] : (val["PH-Comment"] ? val["PH-Comment"] : null)) : null) : null,
            IsRefillSpecific : val && val["IsRefillSpecific"] ? val["IsRefillSpecific"] : null,
            Id : null,
            CreatedDtTm : val && val["CreatedDtTm"] ? val["CreatedDtTm"] : null,
            ModifiedDtTm : val && val["ModifiedDtTm"] ? val["ModifiedDtTm"] : null,
            ClinicalSignfName : val && val["ClinicalSignfName"] ? val["ClinicalSignfName"] : null,
            EffortLvlName : val && val["EffortLvlName"] ? val["EffortLvlName"] : null,
            IsActive : val && val["IsActive"] ? val["IsActive"] : null,
            PrescrefillId : val && val["PrescrefillId"] ? val["PrescrefillId"] : null,
            ProfServName : val && val["ProfServName"] ? val["ProfServName"] : null,
            RxId : val && val["RxId"] ? val["RxId"] : null,
            ServReasonName : val && val["ServReasonName"] ? val["ServReasonName"] : null,
            ServResult : val && val["ServResult"] ? val["ServResult"] : null,
            TenantId : val && val["TenantId"] ? val["TenantId"] : null,
        });
    }

    checkAndReplaceSpclChars(searchedWords) {
        const specialChars = '\\\^\$\.\|\?\*\+\(\)\[\]\{\}'; // list of special chars which have diff meaning in RegExp: \,^,$,.,|,?,*,+,(),[],{}
        specialChars.split('').map(char => {
            if (searchedWords.includes(char)) {
                searchedWords = searchedWords.replaceAll(char,("\\"+char));
            }
        });
        return searchedWords;
    }
    getCurrentDate(){
        const date = moment(new Date()).format(MsgConfig.DATE_FORMAT);
        return date;
    }
    IsHOAenabled(rxFG: any) {
       return (rxFG.Patient.InterfaceId === 3) && (rxFG.rxType === 'nr' || rxFG.rxType === "rf" || rxFG.rxType === "nrf" )
    }
    IsPatientHOAenabled(rxFG: any) {
        return (rxFG.Patient.InterfaceId === 3);
    }
    IsHOAGenerated(rxFG: any) {
        return rxFG?.PrescReFill?.IsHOADefined;
    }
    convertFile(file : File) : Observable<string> {
        const result = new ReplaySubject<string>(1);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => result.next(this._comnSvc.encryptData(event.target.result.toString()));
        return result;
      }
}
