import { SystemData } from "./../../../models/system-data.model";
import { BaseUtils } from "./../../../utils/base.utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SystemSetting, AccCategoryE, RxE, PatientHistoryE, PaginationModel } from "src/app/models";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    OnChanges,
    Output,
    EventEmitter,
    SimpleChanges,
    OnDestroy,
    HostListener,
    ChangeDetectorRef
} from "@angular/core";
import {
    Unlisten,
    KeyboardShortcuts,
    RxService,
    CommonService,
    EditDrugService,
    EditPrescriberService,
    AlertService,
    UserService,
    PatPreDrugModalService
} from "../../../services";
import {
    PatientHistory,
    RxHistoryParams,
    GridParameters,
    RxPatientDetails,
    RxPatientModel,
    CloneRx
} from "../../../models";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as _ from "lodash";
import { RxUtils, ColorCode, NRxUtils, CommonUtil, WijimoUtil } from "src/app/utils";
import * as moment from "moment";
import { PrivMaskGuard } from "src/app/guards";
import { Subject } from "rxjs";
import { RxStore } from "src/app/store/rx.store";
import { NRxSaveUtils } from "src/app/utils/nRx-save.util";
import { EdRxComponent } from "../../ed-rx/ed-rx.component";
import { CommonStore } from "src/app/store";
import { debounceTime, takeUntil } from "rxjs/operators";
import { RxTotalQtyComponent } from "../rx-total-qty/rx-total-qty.component";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "../../../utils/rx-color.util";
import { NotesHistoryComponent } from "../notes-history/notes-history.component";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { FaclityService } from "src/app/services/faclity.service";
import { Verificationsenum } from "src/app/models/Verifications.enum";
import { CommonNotesLogComponent } from "../common-notes-log/common-notes-log.component";
import { LogicoyService } from "src/app/services/logicoy.service";
import { FormatsUtil } from "src/app/utils/formats.util";
import { CurrentViewComponent } from "../current-view/current-view.component";
import { IntegerFloatPipe } from "src/app/pipes/integer-float-format.pipe";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-rx-history",
    templateUrl: "./rx-history.component.html"
})
export class RxHistoryComponent implements OnInit, OnDestroy {
    patientHistory: any;
    filterId = 0;
    patientID: number;
    patientRxHistoryWJ: any;
    flexGrid: any;
    actvHeaders: string[] = [];
    hasPatientHistory = true;
    filter: any = "All";
    filterList = [
        { name: "All", id: 0 },
        { name: "Unbilled", id: 1 },
        { name: "Billed", id: 2 },
        { name: "Deferred", id: 3 }
    ];
    dateFilter = [
        { name: "All", id: 0 },
        { name: "Last 1 Yr", id: 1 },
        { name: "Last 2 Yr", id: 2 },
        { name: "Last 6 Mon", id: 3 },
        { name: "Current", id: 4 }
    ];
    @ViewChild("CommonWarning", {static: true})
    CommonWarning: any;
    totalFiled: number;
    modelRef: any;
    // unlisten: Unlisten;
    // keyboardShortcuts: KeyboardShortcuts;
    totalSelectedRphRxs: any[] = [];
    rxHistoryParams: RxHistoryParams;
    showDateRange: boolean;
    dateRange: number;
    totalCount: number;
    pageCount: number;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    filteredCount: number;
    showExpiredRx = true;
    showRefills = true;
    showDue: boolean = false;
    showCheckBox: boolean = false;
    allSelect: boolean = false;
    rxSelectDue: boolean = false;
    activeScreen: string;
    isFileDueDateDisplay: boolean = true;
    _wijHeight: any;
    loadRxHistory: boolean;
    rxHeaders: string[] = [];
    patientRxInfoWJ: CollectionView;
    rxInfo: any;
    multiCheck = false;
    selectedRxs: any[] = [];
    totalCopayForSelectedRxs: any=null;
    slctdRxObj: any = {};
    isFiledRx: boolean;
    systemStng: SystemSetting;
    FilterFG: FormGroup;
    inactiveRxheaders: any;
    searchValue: string;
    prescCountData: any;
    accessPrivCate: any;
    rxPrivs: any;
    countForSelectDue: number = 0;
    showRefillData: any;
    showRefillPagination: boolean;
    startIndex = 0;
    maxRecords = 30;
    totalRefCount: number;
    systemData: SystemData;
    openOverride: boolean;
    privType: any;
    selectedrxNo: any;
    fromRph: boolean = false;
    selectedrxId: any;
    categoryId: any;
    overrideEndpoint: any;
    inactiveHeader: string[] = [];
    isFromModal: boolean;
    count: number = 0;
    rxAlreadyExists: boolean = false;
    hasPrivToShowPrice: boolean;
    externalSearchParams: any;
    prescriptionQuantities: any[] = [];
    actionRphStr: string = "";
    noOfColums = 21;
    rxHistoryData: any;
    showGroupView: any;
    groupHeaders: string[];
    FrmChild: boolean;
    selectedRfNo: any;
    wjHeaders: any;
    wjHeadersForGropView: any = [];
    wjHeadersForGropViewDetals: any;
    _wijHeightDiv: string;
    isSelectedType: string= "";
    RfOrRx: any;
    isfromEditRx = false;
    totalPriv : any;
    totalInsPaid: any;
    isNoteType: string = "";
    selectedItemForNoteHistory: any;
    billToStatus: string = "All";
    selectedRx: any;
    selectedRxForBenefitCheck: any;
    isShowDrugValuesPop: boolean = false;
    drugInfo: any;
    activeModal: any;
    selectedBenefitCheckType: string;
    modalRefTrOut: NgbModalRef;
    unsubscribe$: Subject<void> = new Subject();
    TotalRxInfo: any[] = [];
    @Output() closePopUp = new EventEmitter();
    reviewXDays: any;
    refillsDueList : any[] = [];
    isLogicoyReg: any;
    totalFileRxs = 0;
    RefReqIds: any;
    integerFloatFormat = new IntegerFloatPipe();
    @Input()
    set MultiCheck(mc: boolean) {
        this.multiCheck = mc;
    }

    @Input() RxFGFormGroup: FormGroup;
    @Input()
    set IsFromModal(mc: boolean) {
        this.isFromModal = mc;
    }

    @Input()
    set openModalFr(data: string) {
        if (data) {
            this.isFromModal = true;
        }
    }

    @Input()clearcheckbox: boolean;
    
    @Input()
    set ActiveScreen(as: string) {
        if (as) {
            this.activeScreen = as;
        }
    }

    @Input()
    set FromRphModal(bl: boolean) {
        if (bl) {
            this.fromRph = bl;
        }
    }

    @Input()
    set ShowCheckBox(bl: boolean) {
        if (bl) {
            this.showCheckBox = bl;
        }
    }

    @Input()
    set ShowDue(bl: boolean) {
        if(bl) {
            this.showDue = bl;
        }
    }
    @Input() IsFromDocumentQueue: boolean ;
    @Input()
    set showForAll(str: string) {
        this.actionRphStr = str;
        if (str === "ExpiredRxs") {
            this.showExpiredRx = false;
        } else if (str === "FiledRxs") {
            this.showExpiredRx = true;
            this.rxHistoryParams.Billstatus = "f";
        } else if (str === "Awaiting Pickup Rxs") {
            this.showExpiredRx = true;
            this.rxHistoryParams.Billstatus = "awp";
        } else if (str === "Awaiting RPH Verification Rxs") {
            this.showExpiredRx = true;
            this.rxHistoryParams.Billstatus = "awrph";
        } else if (str === "Refill Due Rxs") {
            this.showExpiredRx = true;
            this.rxHistoryParams.Billstatus = "refdue";
        }
    }

    @Input()
    set PatientId(pi: number) {
        if (pi) {
            this.patientID = pi;
            this.rxInfo = [];
            this.rxHistoryParams.patientId = this.patientID;
            this.setDate(
                this._commonSer.getSetttingValue(
                    "PatientSettings",
                    "PAT_HISTORY_VIEW"
                ) === "Current Rxs Only" ? "Current" : this._commonSer.getSetttingValue(
                    "PatientSettings",
                    "PAT_HISTORY_VIEW"), false
            );
        }
    }

    @Input()
    set ExternalSearchParams(pi: any) {
        if (pi) {
            this.externalSearchParams = pi;
            this.rxInfo = [];
            this.setPage(1);
        }
    }

    @Input()
    set ShowDateRange(dr: boolean) {
        this.showDateRange = dr;
    }

    @Input()
    set DateRange(dr: number) {
        this.dateRange = dr;
        // if (dr) {
        //     this.rxHistoryParams.DateRange = dr;
        // }
    }



    @Input()
    set PageSize(ps: number) {
        if (ps) {
            this.pageSize = +ps;
        }
    }

    @Input()
    set LoadRxHistory(lr: boolean) {
        this.loadRxHistory = lr;
    }

    @Input()
    set fromEditRx(lr: boolean) {
        this.isfromEditRx = lr;
    }
    @Input() SearchParams: any;

    @Input()
    set ReviewXDays(days: any) {
        this.reviewXDays = days;
    }

    @Output()
    hasRxHistory = new EventEmitter();

    @Output()
    emitRxInfo = new EventEmitter();

    @Output()
    EmitPatientHistory = new EventEmitter();

    @Output()
    LoadPatientHistory = new EventEmitter();

    @Output()
    EmitCanDeactivate = new EventEmitter<any>();

    @Output()
    SelectedRxToEdit = new EventEmitter<any>();

    @Output()
    CloseHistModal = new EventEmitter<any>();

    @Output()
    SelectedPrescription = new EventEmitter<any>();

    @Output()
    loadRxHistoryEmit = new EventEmitter<any>();

    @Output() closeEditRxPopup = new EventEmitter();

    @Output() closePopUpFromRx = new EventEmitter();



    @ViewChild("filedRx", { static: true })
    filedRx: any;

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef) { // esc
            this.modelRef.close();
            this.modelRef = false;
            event.preventDefault();
        }
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
        this.gridHeightDefine();
    }

    constructor(
        private _rxService: RxService,
        private _modalService: NgbModal,
        private _rxUtils: RxUtils,
        private _commonSer: CommonService,
        private _fb: FormBuilder,
        private _baseUtls: BaseUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _colorCode: ColorCode,
        private _router: Router,
        private _rxStore: RxStore,
        private _nrxUtls: NRxUtils,
        private _saveRxUtls: NRxSaveUtils,
        private _alertServ: AlertService,
        private _comnUtil: CommonUtil,
        private _commnStore: CommonStore,
        private _userService: UserService,
        private _wijimoUtils: WijimoUtil,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx,
        private _patPreDrugModalServ: PatPreDrugModalService,private _commonStore: CommonStore,private _cdr: ChangeDetectorRef,
        private _dateCheck: DateCheckUtil,
        private _logicoy: LogicoyService,
        private _formatsUtil:FormatsUtil
    ) {
        this._commonSer.systemData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.systemData = resp;
            }
        });
        this.rxHistoryParams = {
            patientId: 0,
            Billstatus: "a",
            StartDate: null,
            EndDate: null,
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            OrderByColumn: null,
            IsShowExpRefills: null,
            drug: null,
            GroupView: false,
            showCurrentRx:  false,
            AllowRefillingTransferRx: null,
            fromRph : false
        };

        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.totalPriv = PatientHistoryE
    }

    onChanges = new Subject<SimpleChanges>();

    ngOnInit() {
        sessionStorage.removeItem("ReferencePrescReFillId")
        this.isLogicoyReg = this._commonSer.PMPNow$["source"]["value"];
        this.RfOrRx = this._commonSer.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
        if (!this.fromRph) {
            this.FilterFG = this._fb.group({
                dateRng: "",
                drugName_NDC: null
            });
        }
        this._commonSer.systemSettings$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
            this.systemStng = resp;
        });
        if (!this.fromRph) {
            this.FilterFG.controls["dateRng"].patchValue(
                this._commonSer.getSetttingValue(
                    "PatientSettings",
                    "PAT_HISTORY_VIEW"
                ) === "Current Rxs Only" ? "Current" : this._commonSer.getSetttingValue(
                    "PatientSettings",
                    "PAT_HISTORY_VIEW")
            );
        }
        if (this.accessPrivCate) {
            this.hasPrivToShowPrice = this.checkHasPrivsToAccess(this.accessPrivCate.Rx, this.rxPrivs.ShowRxPriceInPatientHistory);
        }
         this._commonSer.setResetPresc$.pipe(
            debounceTime(1000)
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((resp) => {
            if (resp !== null) {
                this.gridHeightDefine();
            }
         });
    }

    ngOnChanges() {
        if (this.clearcheckbox)
            this.getPatientHistory(true, true);
       
    }

    PMPNow() {
        this._logicoy.PMPNow(this.SearchParams && this.SearchParams.controls && this.SearchParams.controls.Patient ? this.SearchParams.controls.Patient.value : this.SearchParams)
    }
    focusOnCancel(event) {
        if (document.getElementById("patHisCancel")) {
            event.preventDefault();
            document.getElementById("patHisCancel").focus();
        }
    }
    triggerCancel() {
        if (document.getElementById("PatHistCloseBtn")) {
            document.getElementById("PatHistCloseBtn").click();
        }
    }
    drugFilter(val) {
        this.rxHistoryParams["drug"] = val ? val : null;
        this.setPage(1, "dateandbilled", true);
    }

    setDate(val, isType: boolean = true) {
       this.isSelectedType = "";
        if (!this.fromRph) {
            val = isType ? (this.FilterFG.controls["dateRng"].value) : val;
             this.isSelectedType = val;
            this.rxHistoryParams.EndDate = moment(new Date()).format("YYYY-MM-DD");
            if (this.activeScreen !== "Rph") {
                switch (val) {
                    case "All": // All
                        this.rxHistoryParams.StartDate = null;
                        this.rxHistoryParams.EndDate = null;
                        break;
                    case "Last 1 Yr": // Last 1 Year
                        this.rxHistoryParams.StartDate = moment()
                            .subtract(1, "year")
                            .format("YYYY-MM-DD");
                        break;
                    case "Last 2 Yr": // Last 2 years
                        this.rxHistoryParams.StartDate = moment()
                            .subtract(2, "year")
                            .format("YYYY-MM-DD");
                        break;
                    case "Last 6 Mon": // Last 6 months
                        this.rxHistoryParams.StartDate = moment()
                            .subtract(180, "days")
                            .format("YYYY-MM-DD");
                        break;
                    case "Current": // Current
                    this.rxHistoryParams.showCurrentRx = true;
                        this.rxHistoryParams.StartDate = moment()
                            .subtract(0, "month")
                            .startOf("month")
                            .format("YYYY-MM-DD");
                        break;
                    default:
                        break;
                }
            } else {
                this.rxHistoryParams.StartDate = moment()
                .subtract(this.reviewXDays, "days")
                .format("YYYY-MM-DD");
            
            }  
        } else {
            this.rxHistoryParams.StartDate = null;
            this.rxHistoryParams.EndDate = null;
        }
        if(this.isSelectedType === "Current" || this.showDue) {
            this.rxHistoryParams.showCurrentRx =  (this.isSelectedType === "Current") ? true : false;
            this.showRefill("", true);
        } else {
            this.setPage(1, "dateandbilled", isType);
        }

    }
    openRxTotalQtyComponent(item) {
        const modalRef = this._modalService.open(
            RxTotalQtyComponent,
            {
                backdrop: false,
                size: "sm",
                windowClass: "mode_width_34 modal-center-to-page",
                keyboard: false
            }
        );
        modalRef.componentInstance.RxNumber = item["PrescNum"];
        modalRef.componentInstance.AuthNumber = item["Auth#"];
        modalRef.componentInstance.RefNumber = item["Ref#"];
        modalRef.componentInstance.FromScreen = "RxHistForTotalQuantityRem";
        modalRef.componentInstance.RxStatusObj ={RxHoverOver : item["RxHoverOver"] , RxColorCoding : item["rxColorCoding"]['classForBackground'] };
        modalRef.componentInstance.CloseRxTotalQtyComponentPop
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(x => {
            modalRef.close();
            localStorage.setItem("IsFromRedFlag", this._commonSer.encryptData("true"));
            this.closePopUpFromRx.emit("close");
            if (this.isFromModal && !x) {
                if (this.isfromEditRx) {
                    this.closeEditRxPopup.emit("close");
                }
                this.CloseHistModal.emit();
            } else if (this.fromRph) {
                this.CloseHistModal.emit();
            }
        });
    }
    getPatientHistory(clearIsSelect?: any, skipPatchHeader?: boolean): void {
        if (clearIsSelect) {
            this.selectedRxs = [];
            this.emitRxInfo.emit(this.selectedRxs);
        }
        this.rxInfo = [];
        if (!this.showExpiredRx) {
            this.rxHistoryParams.IsShowExpRefills = true;
        } else {
            this.rxHistoryParams.IsShowExpRefills = false;
        }
        let dataToPost;
        if (this.activeScreen === "DocQueue") {
            dataToPost = this.externalSearchParams;
        } else {
            dataToPost = this.rxHistoryParams;
        }
        if (this.rxHistoryParams.Billstatus === "refdue" || this.rxHistoryParams.Billstatus === "f") {
            this.rxHistoryParams.GroupView = false;
            this.showGroupView = false;
        }

        // this._commonSer.startLoader();
        this.rxHistoryParams.showCurrentRx =  (this.isSelectedType === "Current") ? true : false;
        this.isSelectedType === "Current" ? this.showRefill("", true) :
        this._rxService
            .getPatientRxHistory(dataToPost)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this._rxStore.storeRxHistory(resp);
                this.SelectedPrescription.emit(null);
                if (resp && resp.totalRxCount > 0) {
                    this.rxHistoryData = resp;
                    this.prescriptionQuantities = resp.prescriptionQuantities ? resp.prescriptionQuantities : [];
                    this.totalCount = resp.totalRxCount;
                    this.showRefillPagination = false;
                    this.RefReqIds = resp.RefReqSentIds;
                    if (this.rxHistoryParams.PageSize === 1000) {
                        this.patientHistory = resp.rxHistory;
                        this.showCummulativeView(clearIsSelect);
                    } else {
                        this.patientHistory = resp.rxHistory;
                        this.prescCountData = resp.PrescRefillCounts;
                        this.showCummulativeView(clearIsSelect);
                        // this.showDur()
                    }
                    if (this.patientHistory && this.patientHistory.length) {
                        this.assignCheckBox();
                    } else {
                        this.showCheckBox = false;
                    }
                } else {
                    this.hasPatientHistory = false;
                    this.hasRxHistory.emit(false);
                    this.patientHistory = [];
                    this.patientRxHistoryWJ = [];
                }
                if(this.activeScreen !== 'PatHis')
                    this.EmitPatientHistory.emit(resp);
                this.loadRxHistory = false;
                this.totalFiled = resp.FiledRxCount;
            });
    }

    setPage(page: number, value?: string, skipPatchHeader?: boolean) {
        this.allSelect = false;
        this.pageNumber = page;
        if (this.activeScreen === "DocQueue") {
            this.externalSearchParams.PageNumber = this.pageSize * (this.pageNumber - 1);
        } else {
            this.rxHistoryParams.PageNumber = this.pageSize * (this.pageNumber - 1);
        }
        if (value === "dateandbilled") {
            this.selectedRxs = [];
            this.emitRxInfo.emit(this.selectedRxs);
        }
        if(this.isSelectedType && this.isSelectedType === "Current" || this.showDue) {
       this.rxHistoryParams.showCurrentRx =  (this.isSelectedType === "Current") ? true : false;
            this.showRefill("", true);
        } else {
            this.getPatientHistory(true, skipPatchHeader);
        }

    }

    assignCheckBox() {
        this.count = 0;
        this.patientHistory.forEach((item, i, arr) =>
            this.selectedRxs.map(val => {
                if (val.PrescReFill.Id === item.PrescReFill.Id) {
                    this.count++;
                    return;
                }
            }));
        if (this.count === this.patientHistory.length) {
            this.allSelect = true;
        }
    }

    setSize(size: number) {
        this.allSelect = false;
        this.selectedRxs = [];
        this.emitRxInfo.emit(this.selectedRxs);
        this.slctdRxObj = {};
        this.pageSize = size;
        this.maxRecords =  size;
        if (this.activeScreen === "DocQueue") {
            this.externalSearchParams.PageSize = this.pageSize;
        } else {
            this.rxHistoryParams.PageSize = this.pageSize;
        }
        this.rxHistoryParams.PageSize = this.pageSize;
        this.setPage(1, null, true);
    }

    setRefPage(page: number) {
        //this.slctdRxObj = {};
        this.startIndex = page;
        this.startIndex = this.maxRecords * (page - 1);
        const dateRng = this.FilterFG && this.FilterFG.value ? this.FilterFG.value["dateRng"] : "";
        if(dateRng === "Current" || this.showDue) {
            this.rxHistoryParams.showCurrentRx = (dateRng === "Current") ? true : false;
            this.showRefill("pageRef" , true);
        } else {
            this.showRefill("pageRef");
        }

    }

    setRefSize(size: number) {
        this.selectedRxs = [];
        this.emitRxInfo.emit(this.selectedRxs);
        this.slctdRxObj = {};
        this.maxRecords = size;
        const dateRng = !this.showDue ? this.FilterFG.value["dateRng"] : "";
        // const dateRng = this.FilterFG.value["dateRng"];
        if(dateRng === "Current" || this.showDue) {
            this.rxHistoryParams.showCurrentRx =  (dateRng === "Current") ? true : false;
            // this.rxHistoryParams.showCurrentRx = true;
            this.showRefill("pageRef" , true);
        } else {
            this.showRefill("pageRef");
        }
    }

    checkAll(isChecked, flexGrid) {
        this.rxAlreadyExists = false;
        flexGrid.collectionView.items.map(elmem => {
            elmem.IsSelected = isChecked;
        });
        if (this.patientHistory) {
            if (isChecked) {
                if (this.showDue && this.selectedRxs && this.selectedRxs.length) {
                    this.patientHistory.forEach(item => {
                        this.selectedRxs.map(val => {
                            if (val.PrescReFill.Id === item.PrescReFill.Id) {
                                this.rxAlreadyExists = true;
                                return;
                            }
                        });
                        if (!this.rxAlreadyExists) {
                            this.selectedRxs.push(item);
                        }
                        this.rxAlreadyExists = false;
                    });
                } else {
                    this.patientHistory.map(item => {
                        this.selectedRxs.push(item);
                    });
                }
            } else {
                this.patientHistory.forEach(item => {
                    this.selectedRxs.map((val, i) => {
                        if (val.PrescReFill.Id === item.PrescReFill.Id) {
                            this.selectedRxs.splice(i, 1);
                            return;
                        }
                    });
                });
            }
        }
        this.selectedRxs = Object.keys(this.selectedRxs).map(
            i => this.selectedRxs[i]
        );
        this.emitRxInfo.emit(this.selectedRxs);
    }

    showDueRefills() {
        const today: string = moment().format("MM/DD/YYYY");
        this.rxSelectDue = false;
        this.countForSelectDue = 0;
        this.allSelect = false;
        this.flexGrid.collectionView.items.map(elem => {
            const colors = this._colorCode.showRxColorRefactoring(elem, "Rx");
            if(colors && colors.bg && colors.bg === "darkgreen" && ((elem["Status"] !== "T") || (elem["Status"] === "T" && !(parseInt(this.RfOrRx))))) {
                elem.IsSelected = true;
            } else {
                elem.IsSelected = false;
            }
        });
        this.patientHistory.map(item => {
            if (!moment(item.PrescReFill.NextFillDt, "YYYY-MM-DD").isAfter(moment().format("YYYY-MM-DD"))) {
                this.countForSelectDue++;
                if (this.selectedRxs && this.selectedRxs.length) {
                    this.selectedRxs.map((val, i) => {
                        if (val.PrescReFill.Id === item.PrescReFill.Id) {
                            this.rxSelectDue = true;
                            return;
                        }
                    });
                    if (!(this.rxSelectDue || moment(item.PrescReFill.NextFillDt).format("MM/DD/YYYY") === today)) {
                        this.selectedRxs.push(item);
                    }
                    this.rxSelectDue = false;
                } else {
                    if (!(moment(item.PrescReFill.NextFillDt).format("MM/DD/YYYY") === today)) {
                        this.selectedRxs.push(item);
                    }
                }
            } else {
                this.selectedRxs.map((val, i) => {
                    if (val.PrescReFill.Id === item.PrescReFill.Id) {
                        this.selectedRxs.splice(i, 1);
                        return;
                    }
                });
            }

        });
        if (this.patientHistory.length === this.countForSelectDue) {
            this.allSelect = true;
        }
        this.selectedRxs = Object.keys(this.selectedRxs).map(
            i => this.selectedRxs[i]
        );
        this.emitRxInfo.emit(this.selectedRxs);
    }

    getHistoryAndSetView(value) {
        this.rxHistoryParams.GroupView = value;
        if (this.showRefills) {
            this.setPage(1, null, true);
        } else {
            this.setRefPage(1);
        }
    }

    showCummulativeView(clearIsSelect?: any) {
        this.showGroupView = this.rxHistoryParams.GroupView;
        if (this.rxHistoryParams.GroupView) {
            this.generateGroupWJ(this.rxHistoryData["OriginalRefillhistory"], this.rxHistoryData["rxHistory"], clearIsSelect);
        } else {
            this.generatePatientRxHistoryWJ(this.rxHistoryData["rxHistory"], this.rxHistoryData["FiledRxCount"], clearIsSelect);
        }
    }

    showInsideGrid(item) {
        return item.items;
    }
    initInsideGride(flex: wjcGrid.FlexGrid) {
        flex.focus();
        // flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
    }

    generateGroupView(PrescNum, RfNum, clearIsSelect) {
        let result = null;
        if (this.rxHistoryData["PartialFillhistory"] && this.rxHistoryData["PartialFillhistory"].length) {
            const data = this.rxHistoryData["PartialFillhistory"].filter(v =>
                v["Prescription"]["PrescNum"] === PrescNum && v["PrescReFill"]["ReFillNum"] === RfNum);
            if (data) {
                result = data.map((t, i) => {
                    return this.generatingWJCols(t, clearIsSelect, i);
                });
            }
        }
        return result;
    }

    generateGroupWJ(orgRx, patHis, clearIsSelect) {
        this.hasPatientHistory = true;
        this.hasRxHistory.emit(this.rxHistoryParams);
        let orgList = Object.assign([], orgRx);
        let data1 = [];
        if(orgList && orgList.length){
            orgList.map((oRx, i) => {
                if (patHis && patHis.length) {
                     patHis.filter(v => {
                        if(oRx["PrescNum"] === v["Prescription"]["PrescNum"] && !oRx["RefillNum"] && !v["PrescReFill"]["ReFillNum"] && (v["PrescReFill"]["PartialFillNo"] == 1)){
                            data1.push(oRx);
                        }
                    }
                ) }
            })
            orgList = data1;
        }
       if(orgRx && orgRx.length){
            orgRx.map(v => {
            const hasData = patHis.findIndex(patRx => v["PrescNum"] === patRx["Prescription"]["PrescNum"]
             );
              if (hasData === -1) {
                  const index = orgList.findIndex(k => k["PrescNum"] === v["PrescNum"]);
                  if (index > -1) {
                      orgList.splice(index, 1);
                  }
              }
          });
       }
        if (orgList && orgList.length) {
            let data: any;
            this.patientRxHistoryWJ = new CollectionView(
                orgList.map((oRx, i) => {
                    if (patHis && patHis.length) {
                        data = patHis.filter(v => oRx["PrescNum"] === v["Prescription"]["PrescNum"]);
                    if (data && data.length) {
                        this.sortDesc(data);
                        const k = {};
                        k["RowId"] = ((this.pageNumber - 1) * this.pageSize) + i;
                        k["Rx#"] = this.checkRefill(
                            data[data.length-1].Prescription.PrescNum,
                            data[data.length-1].Prescription.ReFillNum,
                            0
                        );
                        k["PrescNum"] = data[data.length-1].Prescription.PrescNum;
                        k["Auth#"] = data[data.length-1].Prescription.ReFillNum;
                        let TotalDispQty = 0;
                        let TotalCopay = 0;
                        let TotalPrice = 0;
                        let TotalDays = 0;
                        let TotProfit = 0;
                        data.map(tF => {
                            TotalDispQty = (tF?.PrescReFill?.StatusId === 2 || tF?.Prescription?.RxStatusId === 2 || tF?.RxBill?.StatusId === 6) ? 0 : TotalDispQty +  tF["PrescReFill"]["DispQty"] ;
                            TotalCopay = TotalCopay + tF["PrescReFill"]["TotalCopay"];
                            TotalPrice = TotalPrice + tF["PrescReFill"]["Price"];
                            TotalDays = TotalDays + tF["PrescReFill"]["SupplyDays"] ;
                            TotProfit = TotProfit +  tF["ProfitAmnt"];
                        })
                        k["Total Fills"] = data.length;
                        k["Total Qty Disp"] = TotalDispQty;
                        const orgFill = data.filter(oF => {
                            if(oF["PrescReFill"]["ReFillNum"] === 0 && oF["PrescReFill"]["PartialFillNo"] === 1){
                                return oF;
                            }
                        })
                        k["Total Qty Remaining"] = (orgFill[0]["PrescReFill"]["OrdQty"] * (data[data.length-1].Prescription.ReFillNum + 1)) - TotalDispQty;
                        k["Total CoPay"] = TotalCopay;
                        k["Total Price"] = TotalPrice;
                        k["Total Days"] = TotalDays;
                        k["Total Profit"] = TotProfit;
                        if (this.prescCountData && this.prescCountData.length) {
                            const prescC = this.prescCountData.find(x => +x["KeyAsString"] === data[data.length-1].Prescription.PrescNum);
                            k["PrescCount"] = prescC;
                        } else {
                            k["PrescCount"] = null;
                        }
                        k["Fill#"] = this._commonSer.checkIsPartialFill
                        (data[data.length-1].PrescReFill, data[data.length-1].Prescription)
                        ? data[data.length-1].PrescReFill.PartialFillNo : "";
                        k["fillNo"] = data[data.length-1].PrescReFill.PartialFillNo ? data[data.length-1].PrescReFill.PartialFillNo : 1;
                        data[data.length-1].islatestrefill = k["PrescCount"] && k["PrescCount"]["DocCount"] > 0 ?
                        (k["PrescCount"]["DocCount"] - k["Ref"] === 1 &&
                          k["PrescCount"]["LatestfillCount"] - k["fillNo"] === 0 ? 1 : 0) : 1;
                          k["islatestrefill"] = data[data.length-1].islatestrefill;
                          k["DrugClass"] = data[data.length-1].Drug.drugclass;
                          k["Status"] =  this._comnUtil.getRxStatusinSignleCharacter(data[data.length-1]);
                          let tqtyDisp = "0.000";
                          let dispQty = 0;
                          let totalDispQty = 0;
                          let OrdQty = 0;
                          let filledRefillsQty = 0;
                          data[data.length-1].islatestpartialrefill = data[data.length-1] && data[data.length-1].partialCummulativeDetails ?
                          (data[data.length-1].partialCummulativeDetails["TotFills"] - k["fillNo"] === 0) ? 1 : 0 : 1;
                        if (this.prescriptionQuantities && this.prescriptionQuantities.length) {
                            const rxc = this.prescriptionQuantities.find(v => v["PrescNum"] === data[data.length-1].Prescription.PrescNum);
                            tqtyDisp = rxc && rxc["Qty"] ? (rxc["Qty"]).toFixed(3) : "0.000";
                            dispQty = parseFloat(tqtyDisp);
                            totalDispQty = parseFloat(rxc && rxc["TotalDispensedQty"] ? (rxc["TotalDispensedQty"]).toFixed(3) : "0.000");
                            OrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * (k["Auth#"] + 1)) : 0;
                            filledRefillsQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * rxc["RefillsCount"]) : 0;
                        }
                        k["Rx Qty Rem"] = (OrdQty && ((!data[data.length-1].isRxTransferred && (+this.RfOrRx)) ||
                         !(+this.RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : "0.000");
                        k["Refills Left"] = !data[data.length-1].isRxTransferred  ? (data[data.length-1].PrescReFill.OrdQty ? this.integerFloatFormat.transform(k["Rx Qty Rem"]/data[data.length-1].PrescReFill.OrdQty) : 0) : 0;
                        k["T.Rx Qty Rem"]  =  k["Rx Qty Rem"];
                        k["Qty Rem Partial fill"] = (data[data.length-1].PrescReFill.PartialIndStatus === 'C') ?  "" : (data[data.length-1].islatestpartialrefill ?
                            (data[data.length-1] && data[data.length-1].partialCummulativeDetails ?
                                 data[data.length-1].partialCummulativeDetails.TotRemQty : 0).toFixed(3) : "");
                        k["Qty Rem P.fill"] =  k["Qty Rem Partial fill"];
                        const duedays = ((k["Status"] !== "D") ? Math.round(data[data.length-1].PrescReFill.SupplyDays) :
                          moment().startOf("day").diff(moment.utc(data[data.length-1] && data[data.length-1].RxFile &&
                             (data[data.length-1].RxFile.DueDtTm)? (data[data.length-1].RxFile.DueDtTm): "").startOf("day"), "days")) -
                        moment().startOf("day").diff(moment.utc(data[data.length-1].PrescReFill.FillDtTm).startOf("day"), "days");
                        k["Due Days"] = k["Due in Days"] = (k["Status"] === "T" || (k["Auth#"] === k["Ref"])) ? 0 :
                        !data[data.length-1].islatestrefill ? "" : duedays < 0 ? 0 : duedays;
                        k["DueDays"] = duedays;
                        k["RxExpDate"] = data[data.length-1].RxExpirationDate ?
                         data[data.length-1].RxExpirationDate === "0001-01-01T00:00:00" ? null : data[data.length-1].RxExpirationDate :  null ;
                        k["RxExpirationDate"] = data[data.length-1].RxExpirationDate ?
                        data[data.length-1].RxExpirationDate === "0001-01-01T00:00:00" ? null :
                                moment(data[data.length-1].RxExpirationDate).format("MM/DD/YYYY") : null;

                        k["RxExpirationDue"] = k["RxExpirationDate"] ? moment().diff(
                            k["RxExpirationDate"],
                            "days"
                        ) : null;
                        k["InsRefillDueConsumptionThreshold"] = (data[data.length-1].primaryInsurance &&
                            data[data.length-1].primaryInsurance.refillDueConsumptionThreshold) ?
                            data[data.length-1].primaryInsurance.refillDueConsumptionThreshold : 0;
                        k["RxExpDateByUser"] = data[data.length-1].rxExpiryDateByUser ? data[data.length-1].rxExpiryDateByUser :  null;
                        k["rxExpiryDateByInsuranceOrControlSettings"] =
                         data[data.length-1].rxExpiryDateByInsuranceOrControlSettings ?
                         data[data.length-1].rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ?
                          null : data[data.length-1].rxExpiryDateByInsuranceOrControlSettings :  null;
                        const OrdDate =   new Date(moment.utc(data[data.length-1].PrescReFill.OrderDtTm).local().format(
                            "MM/DD/YYYY"));
                        const FillDate =   new Date(moment.utc(data[data.length-1].PrescReFill.FillDtTm).local().format(
                            "MM/DD/YYYY"));
                        k["rxThreshold"] = data[data.length-1].rxThreshold ? data[data.length-1].rxThreshold : "";
                        k["OrdFillDateDiff"] = (FillDate.getTime() - OrdDate.getTime()) / (1000 * 3600 * 24);
                        k["primaryInsurance"] = data[data.length-1].primaryInsurance ? data[data.length-1].primaryInsurance : null;
                        k["Supply Days"] = k["Days Supply"] = Math.round(data[data.length-1].PrescReFill.SupplyDays);
                        k["Order Status"] = data[data.length-1].PrescReFill.OrderStatus ? data[data.length-1].PrescReFill.OrderStatus : null;
                        k["IsDiscountinued"] = data[data.length-1].Prescription.IsDiscontinued;
                        k["IsDisctFromErx"] = data[data.length-1].Prescription.IsDisctFromErx;
                        k["Copay Paid"] = k["IsCopayPaid"] =  data[data.length-1].PrescReFill.IsCopayPaid ? "YES" : "NO";
                        k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
                        k["RejectReasons"] = data[data.length-1].RejectReasons ? data[data.length-1].RejectReasons.length : 0;
                        k["hoverForStatus"] = data[data.length-1].RejectReasons ? this.updatedRejReasons(data[data.length-1].RejectReasons) : [];
                        k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                        k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(data[data.length-1], "RxObject", "Rx", "hoverOver","", false);
                        let result = null;
                        // data.sort((a: any, b: any) => {
                        //     return a.PrescReFill.PartialFillNo - b.PrescReFill.PartialFillNo;
                        // });
                        result = this.generatingWJCols(data, clearIsSelect);
                        k["items"] = result;
                        k["innerItems"] = result.length;
                        return k;
                    }else {
                        return null;
                    }
                }})
            );
        }

        this.groupHeaders = [
            "Rx#",
            "Auth#",
            "Total Fills",
            "Total Qty Disp.",
            "Total Qty Remaining.",
            "Total CoPay",
            "Total Price",
            "Total Days",
            "Total Profit"
        ];
        this.patchDefaultValHeaders();
    }
    updatedRejReasons(data) {
        const rejectedReasons = [] ; let list = [];
         if (data && data.length) {
             data.map((item: any) => {
                 if(item)
                        list = item.split("\n");
                 if(list && list.length) {
                    list.map((newItem: any) => {
                        if(newItem) {
                            const str = "<b>" + newItem.substring(1, 3) + "</b>";
                            newItem = newItem.replace(newItem.substring(1, 3), str);
                            rejectedReasons.push(newItem);
                        }
                    });
                 }
             });
         }
        return rejectedReasons;
     }
    generatePatientRxHistoryWJ(patRxHist: any, filedRxCount: any, clearIsSelect?: any) {
        this.hasPatientHistory = patRxHist && patRxHist.length ? true : false;
        this.totalFiled = filedRxCount;
        //this.checkHasFiledRxs();
        this.hasRxHistory.emit(this.hasPatientHistory ? this.rxHistoryParams : false);
        this.generatingWJCols(patRxHist, clearIsSelect ? clearIsSelect : false);
        /*this.patientRxHistoryWJ = new CollectionView(
            patRxHist.map((patRx, i) => {
                if (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6)
                    totalFileRxs++;
                return this.generatingWJCols(patRx, i, clearIsSelect ? clearIsSelect : false);
            })
        );*/
        if (!this.totalFiled)
            this.totalFiled = this.totalFileRxs;
        this.patchDefaultValHeaders();
    }

    generatingWJCols(patRxHist, clearIsSelect, i?) {
        const mapDataSet=[];
        patRxHist?.map((patRx, i) => {
                if (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6)
                    this.totalFileRxs++;
        const k = {};
        k["RowId"] = ((this.pageNumber - 1) * this.pageSize) + i;
        k["IsPicked"] = patRx.pickupRx ? patRx.pickupRx.IsPickedUp === true ? true : false : false;
        k["PickedDate"] = patRx.pickupRx?.ExpDtTm ? moment(patRx.pickupRx.ExpDtTm).format(MsgConfig.DATE_FORMAT): "";
        k["Rx#"] = this.checkRefill(
            patRx.Prescription.PrescNum,
            patRx.Prescription.ReFillNum,
            patRx.PrescReFill.ReFillNum
        );
        if (this.prescCountData && this.prescCountData.length) {
            const prescC = this.prescCountData.find(x => +x["KeyAsString"] === patRx.Prescription.PrescNum);
            k["PrescCount"] = prescC;
        } else {
            k["PrescCount"] = null;
        }
        k["PrescNum"] = patRx.Prescription.PrescNum;
        k["Auth#"] = patRx.Prescription.ReFillNum;
        k["Ref"] = k["Ref#"] = patRx.PrescReFill.ReFillNum;
        if(this.RefReqIds?.length) {
            this.RefReqIds.forEach(val=>{
                if(val == patRx.Prescription?.Id) {
                    if(patRx.islatestrefill === 1 && patRx.islatestpartialrefill === 1) {
                        k['refLatest'] = true;
                    }
                }
            });
        }
        
        k["Fill#"] = this._commonSer.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription)
        ? patRx.PrescReFill.PartialFillNo : "";
        k["fillNo"] = patRx.PrescReFill.PartialFillNo ? patRx.PrescReFill.PartialFillNo : 1;
        patRx.islatestrefill = k["PrescCount"] && k["PrescCount"]["DocCount"] > 0 ?
        (k["PrescCount"]["DocCount"] - k["Ref"] === 1 &&  k["PrescCount"]["LatestfillCount"] - k["fillNo"] === 0 ? 1 : 0) : 1;
        k["islatestrefill"] = patRx.islatestrefill;
        k["RxNotes"] = patRx?.rxnoteslog?.length ? patRx.rxnoteslog[0]?.xNote : "";
        k["Insurance"] = this._commonSer.rxInsurance(patRx);
        this.totalInsPaid = 0;
        if (patRx && patRx.RxPay && patRx.RxPay.length) {
            patRx.RxPay.map((i) => {
                if (i.RecType && i.RecType.toUpperCase() == "P")
                this.totalInsPaid = this.totalInsPaid + i.TotAmtPaid; })
                k["Insurance Paid"] = this.totalInsPaid ? ("$" + parseFloat(this.totalInsPaid).toFixed(2)) : "$0.00"
        } else {
            k["Insurance Paid"] = "$0.00"
        }
        k["Status"] =
            (patRx.PrescReFill.StatusId === 4 || patRx.Prescription.RxStatusId === 4) ? "T" :
                (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6) ? "D" :
                    (patRx.PrescReFill.StatusId === 3
                        || patRx.Prescription.RxStatusId === 3) && patRx.RxBill.StatusId === 2 ? "BI" :
                        (patRx.RxBill.StatusId === 2 && patRx.PrescReFill.StatusId !== 4
                            && patRx.Prescription.RxStatusId !== 2) ? "B" :
                            ((patRx.PrescReFill.StatusId === 3 || patRx.Prescription.RxStatusId === 3) &&
                                patRx.RxBill.StatusId === 1) ? "UI" : "U";
        this.isFileDueDateDisplay = (k["Status"] === "D") ? true : false;
        k["Drug Name"] = (patRx?.Drug?.drugname?.trim() + " " + patRx?.Drug?.strength?.trim() + " " + patRx?.Drug?.drugformname?.trim()).toUpperCase();
        k["Qty Dis"] = patRx.PrescReFill.DispQty ? (patRx.PrescReFill.DispQty).toFixed(3) : "0.000";
        k["OweQty"] = patRx.PrescReFill.OweQty ? (patRx.PrescReFill.OweQty).toFixed(3) : "0.000";
        const ActDispQtyVal = (+k["Qty Dis"]) - (+k["OweQty"]);
        k["ActDispQty"] = ActDispQtyVal ? ActDispQtyVal.toFixed(3) : "0.000";
        patRx.islatestpartialrefill = patRx && patRx.partialCummulativeDetails ?
         (patRx.partialCummulativeDetails["TotFills"] - k["fillNo"] === 0) ? 1 : 0 : 1;

        k["Qty Rem"] = (patRx.PrescReFill.PartialIndStatus === 'C') ?  " " : (patRx.islatestpartialrefill ?
        (patRx && patRx.partialCummulativeDetails ? patRx.partialCummulativeDetails.TotRemQty : 0).toFixed(3) : " ");


        k["Supply Days"] = k["Days Supply"] = Math.round(patRx.PrescReFill.SupplyDays);
        k["prescReFillId"] = patRx.PrescReFill.Id;
        k["Copay Paid"] =  k["IsCopayPaid"] =  patRx.PrescReFill.IsCopayPaid ? "YES" : "NO";
        k["selectedRx"] = patRx;
        k["Fill Date"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
            "MM/DD/YYYY hh:mm:ss a"
        );
        k["Next Fill Date"] = moment.utc(patRx.PrescReFill.NextFillDt).local().format(
            "MM/DD/YYYY"
        );
        let tqtyDisp = "0.000";
        let dispQty = 0;
        let totalDispQty = 0;
        let OrdQty = 0;
        let filledRefillsQty = 0;
        let overfillRxOrdQty = 0;
        if (this.prescriptionQuantities && this.prescriptionQuantities.length) {
            const rxc = this.prescriptionQuantities.find(v => v["PrescNum"] === patRx.Prescription.PrescNum);
            tqtyDisp = rxc && rxc["Qty"] ? (rxc["Qty"]).toFixed(3) : "0.000";
            dispQty = parseFloat(tqtyDisp);
            totalDispQty = parseFloat(rxc && rxc["TotalDispensedQty"] ? (rxc["TotalDispensedQty"]).toFixed(3) : "0.000");
            OrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * (k["Auth#"] + 1)) : 0;
            overfillRxOrdQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * ((patRx ? patRx.PrescReFill.primeRxAuthRefillCount : 0) + 1)) : 0;
            filledRefillsQty = rxc && rxc["OrdQty"] ? (rxc["OrdQty"] * rxc["RefillsCount"]) : 0;
        }
        k["TQty Disp."] = tqtyDisp;
        k["PartialQtyRemaining"] = filledRefillsQty - totalDispQty;
        k["TQty Remaining"] = OrdQty && ((!patRx.isRxTransferred && (+this.RfOrRx)) || !(+this.RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : 0;
        k["T.Qty Remaining"] = OrdQty && ((!patRx.isRxTransferred && (+this.RfOrRx)) || !(+this.RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : "0.000";
        k["Rx Qty Rem"] = (OrdQty && ((!patRx.isRxTransferred && (+this.RfOrRx)) || !(+this.RfOrRx)) ? (OrdQty - dispQty).toFixed(3) : "0.000");
        k["T.Rx Qty Rem"]  =  k["Rx Qty Rem"];
        k["Refills Left"] = !patRx.isRxTransferred ? (patRx.PrescReFill.OrdQty  ? (this.integerFloatFormat.transform((k["Rx Qty Rem"]/patRx.PrescReFill.OrdQty))) : 0) : 0;
        k["Qty Rem Partial fill"] = (patRx.PrescReFill.PartialIndStatus === 'C') ?  "" : (patRx.islatestpartialrefill ?
            (patRx && patRx.partialCummulativeDetails ? patRx.partialCummulativeDetails.TotRemQty : 0).toFixed(3) : "");
        k["Qty Rem P.fill"] =  k["Qty Rem Partial fill"];
        k["Rf and Def Due Date"] = (k["Status"] === "D" && patRx.RxFile && patRx.RxFile.DueDtTm) ? moment.utc(patRx.RxFile.DueDtTm).local().format("MM/DD/YYYY") : ((!(k["Refills Left"]) &&  ((k["Qty Rem"] === " ") || (k["Qty Rem"] === "0.000") )) ? "" :  moment.utc(patRx.PrescReFill.NextFillDt).local().format(
            "MM/DD/YYYY"
        )) ;
        k["Price"] = patRx.PrescReFill.Price;
        k["Qty trans. Out"] = patRx.QtyTransferOut ? patRx.QtyTransferOut.toFixed(3): "0.000";
        k["Notes"] = patRx && patRx.Prescription && patRx.Prescription.Remarks ? patRx.Prescription.Remarks : '';
        k["Prescriber Name"] = patRx.Prescriber ? (
            patRx.Prescriber.prescriberlastname + ", " + patRx.Prescriber.prescriberfirstname).toUpperCase() : null;
        k["NDC"] = (patRx && patRx.Drug && patRx.Drug.ndc) ? this._formatsUtil.getNDCFormat(patRx.Drug.ndc): "";
        if (k["Status"] === "D") {
            k["File Due Date"] = (patRx.RxFile && k["Status"] === "D") ? moment.utc(patRx && patRx.RxFile && (patRx.RxFile.DueDtTm)? (patRx.RxFile.DueDtTm): "").local().format(
                "MM/DD/YYYY"
            ) : "";
            k["Defer Reason"] = (patRx.RxFile && k["Status"] === "D") ? patRx.RxFile.DeferredReasonName : "";
            k["FileDueDateHover"] = "<b>Defer Due Date: </b>" + k["File Due Date"];
            k["DeferReasonHover"] = "<b>Defer Reason: </b>" +  k["Defer Reason"];
            k["FileDueDtTm"] = (patRx.RxFile && patRx.RxFile.DueDtTm) ? patRx.RxFile.DueDtTm : null;
        }
        k["isWarn"] =  (patRx.Drug.drugclass && patRx.Drug.drugclass === 2 && k["Fill#"] && (k["Fill#"] == 1)) ? true : false;
        const duedays = ((k["Status"] !== "D") ? Math.round(patRx.PrescReFill.SupplyDays) :  moment().startOf("day").diff(moment.utc(patRx && patRx.RxFile && (patRx.RxFile.DueDtTm)? (patRx.RxFile.DueDtTm): "").startOf("day"), "days")) -
            moment().startOf("day").diff(moment.utc(patRx.PrescReFill.FillDtTm).startOf("day"), "days");
        k["Due Days"] = k["Due in Days"] = (k["Status"] === "T" || (k["Auth#"] === k["Ref"])) ? 0 :
            !patRx.islatestrefill ? "" : duedays < 0 ? 0 : duedays;

        k["DueDays"] = duedays;
        const drugPick = patRx["drugPickVerif"];
        const hasDrugPick = drugPick && drugPick.length > 0 && drugPick[drugPick.length - 1] &&
            (((["p", "P", "p ", "P "].indexOf(drugPick[drugPick.length - 1].Result) > -1) && (!drugPick[drugPick.length - 1].IgnoreInventoryCalculations)) ||
            (((patRx.drugPickVerificationStatus)&&["p", "P", "p ", "P "].indexOf(patRx.drugPickVerificationStatus) > -1) && patRx.PrescReFill.IsIou));
        if (hasDrugPick) {
            k["DVrf?"] = !drugPick[drugPick.length - 1].IsReverified ? Verificationsenum.verified : Verificationsenum.reverified;
        } else if (patRx && (patRx.drugPickVerificationStatus) && (patRx.drugPickVerificationStatus).trim().toLowerCase() === "rv") {
            k["DVrf?"] = "Re-Verify";
        } else if (patRx && (patRx.drugPickVerificationStatus) && (patRx.drugPickVerificationStatus).trim().toLowerCase() === "f") {
            k["DVrf?"] = "FAIL";
        }
        const rphVerify = patRx["rPHVerifs"];
        const rxVerfList = rphVerify && rphVerify.length > 0 ?
        rphVerify.filter(v => ((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"])) : [];
        const hasRxVrify = rxVerfList && rxVerfList.length > 0 && rxVerfList[rxVerfList.length - 1] ?
        rxVerfList[rxVerfList.length - 1] : null;
        k["Rx Vrf?"] =  (patRx && patRx.rph1VerificationStatus) ?
        (patRx.rph1VerificationStatus).trim().toLowerCase() === "r" ? (hasRxVrify ? ( !hasRxVrify.IsReverified ? Verificationsenum.verified : Verificationsenum.reverified) : Verificationsenum.verified):
        (patRx.rph1VerificationStatus).trim().toLowerCase() === "rv" ? "Re-Verify" : "" :
        "";
        const rphVerfList = rphVerify && rphVerify.length > 0 ?
        rphVerify.filter(v => !((v["Status"] ? ((v["Status"]).trim().toLowerCase() === "r") : "") || v["IsForRxVerification"])) : [];
        const hasRphVrify = rphVerfList && rphVerfList.length > 0 && rphVerfList[rphVerfList.length - 1] ?
        rphVerfList[rphVerfList.length - 1] : null;
        if (hasRphVrify && (hasRphVrify.Status + "").trim().toUpperCase() === "V") {
            k["PVrf?"] = (!hasRphVrify.IsReverified ? Verificationsenum.verified : Verificationsenum.reverified);
        }  else if ((hasRphVrify && (hasRphVrify.Status + "").trim().toUpperCase() === "RV")) {
            k["PVrf?"] = "Re-Verify";
        } else if (
            patRx.FollowUpStatus === "F" &&
            (patRx.rph2VerificationStatus === null ||
                patRx.rph2VerificationStatus === "")
        ) {
            k["PVrf?"] = "Follow up";
        }
        k["PatientId"] = patRx && patRx.Patient ? patRx.Patient.patientid : null;
        k["DrugId"] = patRx && patRx.Drug ? patRx.Drug.id : null;
        k["Gender"] = patRx && patRx.Patient && patRx.Patient.gender ? patRx.Patient.gender : null;
        k["Patient Name"] =
            (patRx.Patient.lastname + ", " + patRx.Patient.firstname).toUpperCase();
        const originN = this.systemData.RxOrigin.find(v => v["Id"] === patRx.PrescReFill.RxOriginId);
        k["Rx Origin"] = originN ? originN["Name"] : "";
        k["PrescPhone"] = patRx.Prescriber ? patRx.Prescriber.telephone : null;
        k["PrescId"] = patRx.Prescriber ? patRx.Prescriber.prescriberid : null;
        k["PrescRefilId"] = patRx.PrescReFill.Id;
        k["IsDiscountinued"] = patRx.Prescription.IsDiscontinued;
        k["IsDisctFromErx"] = patRx.Prescription.IsDisctFromErx;
        k["DrugClass"] = patRx.Drug.drugclass;
        k["IsNotesIconDisplay"] = (patRx.Drug.drugclass && patRx.Drug.drugclass === 2 && 
            patRx['PrescReFill']['OrdQty'] && patRx['PrescReFill']['DispQty'] && (patRx['PrescReFill']['OrdQty'] > patRx['PrescReFill']['DispQty']));
        k["RxHistoryPrescriberId"] = patRx.Prescriber ? patRx.Prescriber.prescriberid : null;
        k["RxHistoryDrugId"] = patRx.Drug.id;
        k["RefillDueDate"] = (patRx.RxFile && k["Status"] === "D") ? moment.utc(patRx && patRx.RxFile && (patRx.RxFile.DueDtTm)? (patRx.RxFile.DueDtTm): "").local().format(
            "MM/DD/YYYY"
        ) : moment(patRx.PrescReFill.FillDtTm)
            .add(patRx.PrescReFill.SupplyDays, "days")
            .format("YYYY-MM-DD");
        if (!clearIsSelect) {
            k["IsSelected"] = (this.selectedRxs && this.selectedRxs.length > 0 &&
                this.selectedRxs.filter(val => val.PrescReFill.Id
                    === patRx.PrescReFill.Id).length > 0) ? true : false;
        } else {
            this.selectedRxs = [];
            this.slctdRxObj = {};
            this.allSelect = false;
            k["IsSelected"] = false;
        }
        k["IsBilled"] = this._comnUtil.checkIsGreenBilled(patRx, this.systemData);
        const days = +this._commonSer.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
        const isDrugDiscontinued = patRx.Drug["discontdt"] && moment().startOf('day')
            .isAfter(moment.utc(patRx.Drug["discontdt"]).local().add(days, 'days'));
        k["IsDrugDis"] = isDrugDiscontinued ? 'YES' : 'NO';
        k["RxExpDate"] = patRx.RxExpirationDate ? patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null : patRx.RxExpirationDate :  null ;
        k["RxExpirationDate"] = patRx.RxExpirationDate ?
            patRx.RxExpirationDate === "0001-01-01T00:00:00" ? null :
                moment(patRx.RxExpirationDate).format("MM/DD/YYYY") : null;

        k["RxExpirationDue"] = k["RxExpirationDate"] ? moment().diff(
            k["RxExpirationDate"],
            "days"
        ) : null;
        k["PrescRefillId"] = patRx.PrescReFill.Id ? patRx.PrescReFill.Id : null;
        k["Copay"] = patRx.PrescReFill.TotalCopay ? patRx.PrescReFill.TotalCopay : 0;
        k["Total Amount"] = patRx.PrescReFill.TotalAmt ? patRx.PrescReFill.TotalAmt : 0;
        k["Order Status"] = patRx.PrescReFill.OrderStatus ? patRx.PrescReFill.OrderStatus : null;
        k["PrescriptionId"] = patRx.Prescription.Id;
        k["Sig Lines"] = patRx.SigCodes && patRx.SigCodes.Description ? patRx.SigCodes.Description.toUpperCase() : "";
        k["Tag Name"] = patRx.PrescRefill_RxTags && patRx.PrescRefill_RxTags["Name"] ?
            patRx.PrescRefill_RxTags["Name"].toUpperCase() : "";
        const deliveryInfo = this.systemData.DeliveryMode.find(v => v["Id"] === patRx.PrescReFill.DeliverModeId);
        k["Delivery Method"] = (deliveryInfo && deliveryInfo.Name) ? deliveryInfo.Name : "";
        k["Profit Amount"] = patRx.ProfitAmnt ? patRx.ProfitAmnt : 0;
        k["HasDoc"] = patRx.isRxDocAttached ? "Yes" : "No";
        k["Label Printed?"] = patRx.isLabelPrinted ? "Yes" : "No";

        k["QtyRem"] = patRx.partialCummulativeDetails && patRx.partialCummulativeDetails.TotRemQty ?
        patRx.partialCummulativeDetails.TotRemQty : 0;
        k["Patient Pay"] = patRx.PrescReFill.TotalCopay;
        k["InsRefillDueConsumptionThreshold"] = (patRx.primaryInsurance && patRx.primaryInsurance.refillDueConsumptionThreshold) ? patRx.primaryInsurance.refillDueConsumptionThreshold : 0;
        k["RxExpDateByUser"] = patRx.rxExpiryDateByUser ? patRx.rxExpiryDateByUser :  null;
        k["rxExpiryDateByInsuranceOrControlSettings"] = patRx.rxExpiryDateByInsuranceOrControlSettings ? patRx.rxExpiryDateByInsuranceOrControlSettings === "0001-01-01T00:00:00" ? null : patRx.rxExpiryDateByInsuranceOrControlSettings :  null;
        const OrdDate =   new Date(moment.utc(patRx.PrescReFill.OrderDtTm).local().format(
            "MM/DD/YYYY"));
        const FillDate =   new Date(moment.utc(patRx.PrescReFill.FillDtTm).local().format(
            "MM/DD/YYYY"));
        k["rxThreshold"] = patRx.rxThreshold ? patRx.rxThreshold : "";
        k["OrdFillDateDiff"] = (FillDate.getTime() - OrdDate.getTime()) / (1000 * 3600 * 24);
        k["primaryInsurance"] = patRx.primaryInsurance ? patRx.primaryInsurance : null;
        k["StatusHoverOver"] = this._hoverOver.getStatusHoverOver(k);
        k["RejectReasons"] = patRx.RejectReasons ? patRx.RejectReasons.length : 0;
        k["hoverForStatus"] = patRx.RejectReasons ? this.updatedRejReasons(patRx.RejectReasons) : [];
        k["RxHoverOver"] = this._hoverOver.getRxHoverOver(k, "Rx");
        k["rxColorCoding"] = this._colorCode.showRxColorRefactoring(k,"Rx");
        k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
        k["RxPay"] = patRx.RxPay;
        k["TotalRxAmount"] = patRx.PrescReFill.CoPay ? patRx.PrescReFill.TotalRxAmount : 0
        k["partiallyoRCompRecords"] = this._colorCode.getPartialOrFullPayment(patRx.RxPay);
       const reasonForService: any = [];
       const professionalService: any = [];
       const resultOfService: any = [];
       const levelOfEffort: any = [];
       const coAgentId: any = [];
       const coAgentIdType: any = [];
       const clinicalSig: any = [];
       const pharmacistComment: any = [];
       if(patRx.Dur && patRx.Dur.length){
        patRx.Dur.map(elem=>{
            reasonForService.push(elem.ServReasonName);
            professionalService.push(elem.ProfServName);
            resultOfService.push(elem.ServResult);
            levelOfEffort.push(elem.EffortLvlName);
            coAgentId.push(elem.CoAgentNum);
            coAgentIdType.push(elem.CoAgentIdType);
            clinicalSig.push(elem.ClinicalSignfName);
            pharmacistComment.push(elem.PhComment);
        })
       }
        k["reasonForService"] = reasonForService.filter(a=> a);
        k["professionalService"] = professionalService.filter(a=> a);
        k["resultOfService"] = resultOfService.filter(a=> a);
        k["levelOfEffort"] = levelOfEffort.filter(a=> a);
        k["coAgentId"] = coAgentId.filter(a=> a);
        k["coAgentIdType"] = coAgentIdType.filter(a=> a);
        k["clinicalSig"] = clinicalSig.filter(a=> a);
        k["pharmacistComment"] = pharmacistComment.filter(a=> a);
        k["iouInfo"]=patRx.IouDetails;

        //return k;
            mapDataSet.push(k); 
        });  
        this.patientRxHistoryWJ = new CollectionView(mapDataSet, {
            sortComparer: (a: any, b: any) => {
                return moment(a).isValid() && moment(b).isValid() && (typeof(a) == 'string') && (typeof(b) == 'string') 
                    ? moment(a).diff(moment(b))
                    : null;
            }
        });
        return mapDataSet;
    }

    patchDefaultValHeaders() {
        const storedWJ = this._commnStore.rxWJVal$["source"]["value"];
        if (storedWJ) {
            const oldWJ = storedWJ.filter(v => v.isVisible === true);
            const oldWJN = storedWJ.filter(v => v.isVisible === false);
            if (oldWJ && oldWJ.length) {
                this.rxHeaders = oldWJ.map(col => {
                    return col["header"];
                });
            }
            if (oldWJN && oldWJN.length) {
                this.inactiveHeader = oldWJN.map(col => {
                    return col["header"];
                });
            }
            if (this.rxHeaders && this.rxHeaders.length) {
                this.rxHeaders.map((v, i) => {
                    if (v === "" || v === "Action" || v === null) {
                        this.rxHeaders.splice(i, 1);
                    }
                });
            }
            if (this.inactiveHeader && this.inactiveHeader.length) {
                this.inactiveHeader.map((v, i) => {
                    if (v === "" || v === "Action" || v === null) {
                        this.inactiveHeader.splice(i, 1);
                    }
                });
            }
        } else {
            if (this.hasPrivToShowPrice) {
                this.rxHeaders.splice(13, 0, "Price");
            }
            if (this.activeScreen === "DocQueue") {
                this.rxHeaders.unshift("Patient Name");
            }

            this.inactiveHeader = [
                "IsDrugDis",
                "Total Amount",
                "Sig Lines",
                "Tag Name",
                "Delivery Method",
                "Refills Left",
                "Rf and Def Due Date",
                "HasDoc",
                "TQty Disp.",
                "TQty Remaining",
                "Patient Pay"
            ];
            if (this.isFileDueDateDisplay) {
                this.rxHeaders.push("File Due Date");
            }
            this.inactiveRxheaders = [
                "RxHistoryPrescriberId",
                "Patient Name",
                "RxHistoryDrugId",
                "DrugClass",
                "PrescCount",
                "RefillDueDate",
            ];
        }
    }

    optedRx2(e, d) { }

    checkRefill(rx: string, aref: number, ref: number) {
        if (ref !== 0) {
            return rx + " - R";
        } else {
            return rx;
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row && row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const colors = this._colorCode.showRxColorRefactoring(row.dataItem, "Rx");
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                } 
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            }  else if (panel.cellType === wjcGrid.CellType.Cell && (panel.columns[c].binding === "Copay")) {
                if(row?.dataItem?.IsCopayPaid === "YES") {
                    cell.style.background = "green"
                    cell.style.color = "#fff";
                } else {
                    cell.style.backgroundColor = "#fff";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && (panel.columns[c].binding === "Insurance Paid")) {
                const IsCheck = this._colorCode.getPartialOrFullPayment(row?.dataItem?.RxPay);
                if(IsCheck?.IsPartiallyPaidComp !== null) {
                    if(IsCheck?.IsPartiallyPaidComp) {
                        cell.style.background = "green"
                        cell.style.color = "#fff";
                    } else {
                        cell.style.background = "orange";
                        cell.style.color = "#fff";
                    }
                } else {
                    cell.style.backgroundColor = "#fff";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            }  else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && ["DVrf?", "PVrf?", "Rx Vrf?"].includes(panel.columns[c].binding)) {
                let key = panel.columns[c].binding;
                if ((row.dataItem[key] === "Y") || (row.dataItem[key] === Verificationsenum.verified || row.dataItem[key] === Verificationsenum.reverified)) {
                    if ((+row.dataItem["OweQty"]) && key === "DVrf?") {
                        cell.style.backgroundColor = "#ffc107";
                        cell.style.color = "#fff";
                    } else {
                        cell.style.backgroundColor = "darkgreen";
                        cell.style.color = "#fff";
                    }
                } else if (row.dataItem[key] === "Re-Verify") {
                    cell.style.backgroundColor = "orange";
                    cell.style.color = "#fff";
                }  else {
                    cell.style.backgroundColor = "#fff";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rf and Def Due Date") {
                const colors = this._colorCode.showRfDueDtColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Qty Dis") {
                if(row?.dataItem?.iouInfo?.length && +(row?.dataItem?.OweQty)) {
                    cell.style.color = "#f7941d";
                    cell.style.fontWeight = "bold";
                    cell.style.backgroundColor = "#FFF";
                } else {
                    cell.style.backgroundColor = "#FFF";
                    cell.style.color = "#000";
                    cell.style.fontWeight = "normal";
                }
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
        if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Auth#") {
            cell.style.background = "#FFF";
            cell.style.backgroundColor = "#FFF";
            cell.style.color = "#000";
            cell.style.fontWeight = "normal";
            cell.style.textAlign = "initial";
        }
    };

    updateColumnSize(flex: wjcGrid.FlexGrid) {
        if (flex && flex.columns && flex.columns.length) {
            const noOfCols = flex.columns.filter(v => v.isVisible === true);
            if (noOfCols && this.noOfColums !== noOfCols.length) {
                this.noOfColums = noOfCols.length;

            }
        }
    }

    initGroup(flex: wjcGrid.FlexGrid) {
        this.patchDefWidth();
    }


    init(flex: wjcGrid.FlexGrid) {
        this.flexGrid = flex;
        flex.columnHeaders.rows[0].height = 50;
        flex.columnHeaders.rows[0].wordWrap = true;
        this.gridHeightDefine();
        this.patchDefWidth();
    }

    gridHeightDefine() {
        const element = document.getElementById("patientHistoryGrid");
        if (element) {
            const height = window.innerHeight -
            element.getBoundingClientRect().top +
            window.scrollY - window.scrollY;
            let fitHeight;
            if (this.isFromModal === true) {
                fitHeight = height>900 ? height - 700 :height - 300;
            } else {
                fitHeight = (height > 400 && height < 650) ? height - 175 : (height >= 650 && height < 750) ? height - 235 : (height >= 750) ? fitHeight = height - 260 : height - 195;
            }
            this._wijHeightDiv = (fitHeight + 30) + 'px';
            this._wijHeight = ((fitHeight + (this.showGroupView? 40 : 30)) - 4) + 'px';
        }
    }

    async  patchDefWidth() {
        const patientHistId = document.getElementById("patientHistId");
        const patientHistWidth = patientHistId ? patientHistId.clientWidth : 0;
        const storedWJ = await this._wijimoUtils.getWJSavedData("patientRxHistWJ");
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("rxGroupViewWJ");
        const storedWJ2 = await this._wijimoUtils.getWJSavedData("rxGroupViewDetailsWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("patientRxHistWJ", storedWJ, null, patientHistWidth);
        this.hidePriceColumnBasedPrivallage();
        this.wjHeadersForGropView = this._wijimoUtils.patchDefHeader("rxGroupViewWJ", storedWJ1);
        this.wjHeadersForGropViewDetals = this._wijimoUtils.patchDefHeader("rxGroupViewDetailsWJ", storedWJ2);
    }
    hidePriceColumnBasedPrivallage() {
        const priceColIndex = this.wjHeaders.findIndex((item: any) => { return (item['hName'] === 'Price')  });
        if(priceColIndex && !this.hasPrivToShowPrice) {
            this.wjHeaders.splice(priceColIndex, 1);
        }
    }
    setColumnChange(flex, frmUpd?: boolean) {
        const tWidth = this.calculateTotWidth(flex.columns);
        flex.columns.map((col, i) => {
            ["", "Total Qty Disp",
            "Total Qty Remaining", "Total CoPay", "Total Price",
            "Total Days", "Total Profit"
            ].findIndex(item => item === col.header) > 0 ?
            (col.width = this.calculateColumnWidth(col.width ? col.width : 235, (1662 - tWidth))) :
            ["",
                "Auth#", "Refills Left",
                "Status", "IsDrugDis",
                "Days", "HasDoc",
                "Copay Status",
                "Due in Days", "DVrf?", "Label Printed?",
                "Ref#", "Fill#", "Total Fills", "PVrf?", "Rx Vrf?"
            ].findIndex(item => item === col.header) > 0 ?
                (col.width = this.calculateColumnWidth(col.width ? col.width : 50, (1662 - tWidth))) :
                ["", "Fill Date", "Rf and Def Due Date", "File Due Date", "Insurance",
                ].findIndex(item => item === col.header) > 0
                    ? (col.width = this.calculateColumnWidth(col.width ? col.width : 80, (1662 - tWidth)))
                    : ["", "Qty Dis", "Qty Rem", "Total Amount",
                        "Price", "Copay", "Patient Pay", "Profit Amount", "TQty Disp.", "TQty Remaining"
                    ].findIndex(item => item === col.header) > 0
                        ? (col.width = this.calculateColumnWidth(col.width ? col.width : 95, (1662 - tWidth)))
                        : ["", "Tag Name", "Rx Origin", "Delivery Method", "Rx#", "NDC"
                        ].findIndex(item => item === col.header) > 0
                            ? (col.width = this.calculateColumnWidth(col.width ? col.width : 107, (1662 - tWidth)))
                            : ["", "Sig Lines", "Drug Name", "Prescriber Name", "Patient Name"].findIndex(
                                item => item === col.header
                            ) > 0
                                ? (col.width = this.calculateColumnWidth(col.width ? col.width : 150, (1662 - tWidth)))
                                : ["", "Action"].findIndex(item => item === col.header) > 0 ?
                                    (col.width = this.calculateColumnWidth(col.width ? col.width : 58, (1662 - tWidth)))
                                    : (col.width = 29);
        });
    }

    calculateColumnWidth(value, change) {
        let result = value;
        if (change && change !== 1662 && !Number.isNaN(change)) {
            result = value + change / this.noOfColums;
        }
        return result;
    }

    calculateTotWidth(columns) {
        let tWidth = 0;
        if (columns && columns.length) {
            const noOfCols = columns.filter(v => v.isVisible === true);
            if (noOfCols && noOfCols.length) {
                noOfCols.map(c => tWidth = tWidth + (c.width !== "*" ? c.width : 0));
            }
        }
        return tWidth;
    }

    showExpiredRefills() {
        this.allSelect = false;
        this.slctdRxObj = {};
        this.rxHistoryParams.PageNumber = this.paginationModel.pageNumber;
        this.rxHistoryParams.PageSize = this.paginationModel.pageSize;
        this.pageNumber = this.paginationModel.pageNumber;
        this.selectedRxs = [];
        this.emitRxInfo.emit(this.selectedRxs);
        this.pageSize = this.paginationModel.pageSize;
        this.showExpiredRx = false;
        this.showRefills = true;
        this.showCheckBox = false;
        this.showDue = false;
        this.rxHistoryParams.IsShowExpRefills = true;
        this.getPatientHistory(true, true);
    }

    showAllRefills() {
        //  this.rxHistoryParams.StartDate = null;
        //  this.rxHistoryParams.EndDate = null;
        this.rxHistoryParams.PageNumber = this.startIndex;
        this.rxHistoryParams.PageSize = this.paginationModel.pageSize;//this.maxRecords;
        this.pageNumber = this.paginationModel.pageNumber;
        this.pageSize = this.paginationModel.pageSize;
        this.slctdRxObj = {};
        this.selectedRxs = [];
        this.emitRxInfo.emit(this.selectedRxs);
        this.rxHistoryParams.IsShowExpRefills = false;
        this.rxHistoryParams.GroupView = false;
        this.showGroupView = false;
        this.showExpiredRx = true;
        this.showDue = false;
        this.showCheckBox = false;
        this.showRefills = true;
        this.getPatientHistory(true, true);
    }



    showRefill(value?: string , isFromCurrent : boolean = false) {
        if(!isFromCurrent) {
            this.allSelect = false;
            this.showExpiredRx = true;
            this.rxHistoryParams.IsShowExpRefills = false;
            this.rxHistoryParams.GroupView = false;
            this.showDue = true;
            this.showCheckBox = true;
            this.showRefills = false;
        } else {
            this.showDue = this.showDue ? this.showDue : false;
            this.showRefills = true;
        }

        this.rxHistoryParams.PageNumber = this.startIndex;
        this.rxHistoryParams.PageSize = this.maxRecords;
        this.showGroupView = false;
        if (value !== "pageRef") {
            this.selectedRxs = [];
            this.emitRxInfo.emit(this.selectedRxs);
        }
        this.slctdRxObj = {};

        this.rxHistoryParams.AllowRefillingTransferRx = this._commonSer.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
        if(this.fromRph || this.reviewXDays && this.rxHistoryParams.Billstatus == "refdue"){
            this.rxHistoryParams.fromRph = true;
        } else{
            this.rxHistoryParams.fromRph = false;
        }
        this._rxService
            .getShowRefillData(this.rxHistoryParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.rxHistoryData = resp;
                    this.prescriptionQuantities = resp.prescriptionQuantities ? resp.prescriptionQuantities : [];
                    this.showRefillPagination = true;
                    this.patientHistory = resp.Refills;
                    // this.showRefillData = resp.Refills;
                    this.patientHistory = resp.Refills;
                    this.totalRefCount = resp.TotalCount;
                    this.prescCountData = resp.PrescRefillCounts;
                    this.RefReqIds = resp.RefReqSentIds;
                    this.generatePatientRxHistoryWJ(this.rxHistoryData["Refills"], this.rxHistoryData["FiledRxCount"], true);
                   // this.showCummulativeView(true);
                    if (this.patientHistory && this.patientHistory.length) {
                        this.assignCheckBox();
                    } else {
                        this.showCheckBox = false;
                    }
                    // this.generatePatientRxHistoryWJ(this.showRefillData);
                }
                this.rxHistoryParams.showCurrentRx = false;
            });
    }

    getFilter(value: string) {
        this.billToStatus = value;
        // this.rxHistoryParams.Billstatus = 0;
        if (value === "All") {
            this.rxHistoryParams.Billstatus = "a";
        } else if (value === "Unbilled") {
            this.rxHistoryParams.Billstatus = "u";
        } else if (value === "Billed") {
            this.rxHistoryParams.Billstatus = "b";
        } else if (value === "Deferred") {
            this.rxHistoryParams.Billstatus = "f";
        } else if (value === "Current") {
            this.filterId = 5;
        }
        if (this.activeScreen === "DocQueue") {
            this.externalSearchParams.Billstatus = this.rxHistoryParams.Billstatus;
        }
        this.setPage(1, "dateandbilled", true);
    }

    // TODO need to search through UI
    getDrugFilter(val) {
        this.searchValue = val;
        this.rxHistoryParams.PageSize = 1000;
        this.getPatientHistory();
    }

    checkHasFiledRxs() {
        if (this.totalFiled) {
            this.modelRef = this._modalService.open(this.filedRx, { keyboard: false, windowClass:"large--content"});
        }
    }

    closeModal() {
        if (this.modelRef) {
        this.modelRef.close();
        this.modelRef = null;
        }
    }

    optedRx(isChecked: boolean, checkedVal: any, isKeyEvent?: any) {
        let checkBoxId: HTMLInputElement;
        if (isKeyEvent) {
            if (document.getElementById(checkedVal.RowId)) {
                checkBoxId = document.getElementById(checkedVal.RowId) as HTMLInputElement;
                checkBoxId.checked = !checkBoxId.checked;
                isChecked = checkBoxId.checked;
            }
        }
        if (isChecked) {
            var rxList = this.patientHistory.filter(x => x.PrescReFill.Id == checkedVal.PrescRefillId);
            const optedRx = rxList && rxList.length > 0 ?  rxList[0] : null;
            this.selectedRxs.push(optedRx);
        } else {
            //this.patientHistory[checkedVal.RowId - ((this.pageNumber - 1) * this.pageSize)]
            var rxList = this.patientHistory.filter(x => x.PrescReFill.Id == checkedVal.PrescRefillId);
            const item = rxList && rxList.length > 0 ?  rxList[0] : null;
            this.selectedRxs.map((val, i) => {
                if (val && val.PrescReFill && val.PrescReFill.Id && item && item.PrescReFill &&
                     item.PrescReFill.Id && val.PrescReFill.Id ===item.PrescReFill.Id) {
                    this.selectedRxs.splice(i, 1);
                    return;
                }
            });
        }
        const convrtdArray = Object.keys(this.selectedRxs).map(
            i => this.selectedRxs[i]
        );
        this.selectedRxs = convrtdArray;
        if(!isKeyEvent) {
            this.totalCopayForSelectedRxs = (this.selectedRxs?.length) ? this.selectedRxs.reduce( function(a, b){
                return a + b['PrescReFill']?.['TotalCopay'];
            }, 0):null;
        }
      
        this.emitRxInfo.emit(this.selectedRxs);
    }

    prescriptionSelected(checked, data, index) {
        this.SelectedPrescription.emit(data);
    }

    preventSpaceEvent(event) {
        event.preventDefault();
    }

    onRowDblclicked(s: any, e: any, frm?: string) {
        const ht = s.hitTest(e);
        if (s && s.selectedRows && s.selectedRows[0] && s.selectedRows[0].dataItem) {
            this.selectedrxNo = s.selectedRows[0].dataItem["PrescNum"];
            this.selectedrxId = s.selectedRows[0].dataItem["PrescRefillId"];
        }
        this.FrmChild = !frm ? true : frm === "child" ? true : frm === "parent" && !this.FrmChild ? true : false;
        this._commnStore.ngOnchangesRestrictionAfterClosingEditRx(false);
        if (this.FrmChild) {
            if (frm === "parent") {
                this.selectedRfNo = s && s.selectedRows[0] && s.selectedRows[0].dataItem &&
                 s.selectedRows[0].dataItem["Ref#"] ? s.selectedRows[0].dataItem["Ref#"] : null;
            } else {
                this.selectedRfNo = null;
            }
            if (this.isFromModal) {
                this.CloseHistModal.emit();
            }
            if (ht.cellType === 1) {
                if (this.checkHasPrivsToAccess(
                    this.accessPrivCate.Rx,
                    this.rxPrivs.EditRx)) {
                        this.routeToRx();
                } else {
                    this.openOveride("Rx", "EditRx", ["POST_Rx_GetRxDetails", "PUT_Rx"]);
                }
            }
            sessionStorage.removeItem("ActiveId");
        }
    }

    async routeToRx(val?: any) {
        let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+this.selectedrxNo, +this.selectedrxId, false, this.selectedRfNo, null, null, true);
        if (rxInfo)  {
            rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
            this._nrxUtls.createPatchStoreRxFG(rxInfo);
            await this._nrxUtls.getCommonEndPoints(rxInfo);
        }
        const modalRef = this._modalService.open(
            EdRxComponent,
            {
                backdrop: false,
                size: "lg",
                windowClass: "md-xx-lg comp--modal",
                keyboard: false
            }
        );
        modalRef.componentInstance.SelectedValue = (val && (val["isNPPatientValue"] || val["isNPPatientValue"] === "false")) ? val["isNPPatientValue"] : "true";
        modalRef.componentInstance.openFrom = "PatHis";
        modalRef.componentInstance.CloseEditMode
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(x => {
                modalRef.close();
                if(this.isSelectedType === "Current" || this.showDue) {
                    this.rxHistoryParams.showCurrentRx =  (this.isSelectedType === "Current") ? true : false;
                    this.showRefill("", true);
                    this.showDue = true;
                    this.showRefills = false;
                } else {
                    (this.activeScreen !== 'PatHis')? this.loadRxHistoryEmit.emit() : this.EmitPatientHistory.emit();
                    this.FrmChild = false;
                }
               // this._saveRxUtls.clerAllNwRxInfo(rxInfo);
        });
        modalRef.componentInstance.CloseAndReopen
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(d => {
            modalRef.close();
            if (d) {
                this.selectedrxNo = d["rxNo"];
                this.selectedrxId = d["rfId"];
                this.FrmChild = false;
                this.routeToRx(d);
            } else {
                this.getPatientHistory(true);
                this.loadRxHistoryEmit.emit();
                this.FrmChild = false;
            }
        });
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideEndpoint = endpoint;
    }

    getRxSettingValue(settingType: string, settingValueFor: string) {
        return this._commonSer.getSetttingValue(settingType, settingValueFor);
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    routeToEditRx(item: any) {
        if (this.isFromModal) {
            this.CloseHistModal.emit();
        }
        this.selectedrxNo = item["Rx#"];
        this.selectedrxId = item["PrescRefillId"];
        this.routeToRx();
    }

    toggleColorLegend() {
        this._alertServ.showHelpText({ RxStatus: true, DrugName: true, Status: true });
    }
   async openDurOrNotesTab(item,type?){
        this.selectedRx = this.patientHistory?.find((newItem: any) => { return (newItem['PrescReFill']['Id'] === item['PrescRefilId'])});
        const rxNum = this.selectedRx.Prescription.PrescNum;
        const rfId = this.selectedRx.PrescReFill.Id;
       if(type && (type === 'dur' || type === 'add' || type === 'addNotesfrmButton')) {
        let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+rxNum, rfId, false, null, null, null, true);
        if(rxInfo){
            rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
            this._nrxUtls.createPatchStoreRxFG(rxInfo);
            await this._nrxUtls.getCommonEndPoints(rxInfo);
            const modalRef = this._modalService.open(
                EdRxComponent,
                {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-xx-lg comp--modal",
                    keyboard: false
                }
            );
            modalRef.componentInstance.PatientId= (type == 'dur') ? "Patient7" : "Patient3";
            modalRef.componentInstance.rxFG = this.selectedRx as FormGroup;
            modalRef.componentInstance.IsFromRxHistory = true;
            modalRef.componentInstance.CloseEditMode
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(x => {
                modalRef.close();
                this.getPatientHistory();
        });
        }
       } else {
        const rxNotesLogData: any = await this._nrxUtls.getNotesLog("Rx", this.selectedRx["Prescription"]["Id"], {PrescReFill : this.selectedRx["PrescReFill"]["Id"], PrescNum : this.selectedRx["Prescription"]["PrescNum"] ,  RxType : this.selectedRx["NotePopupscreenType"] ? this.selectedRx["NotePopupscreenType"] : this.selectedRx["rxType"]});
            if (rxNotesLogData?.Note?.length) {
                const modelRef = this._modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
                modelRef.componentInstance.NotesListFrmPage = {Note:  rxNotesLogData.Note};
                modelRef.componentInstance.IsFromPage = "RxHistory";
                modelRef.componentInstance.RxType = this.selectedRx["NotePopupscreenType"] ? this.selectedRx["NotePopupscreenType"] : this.selectedRx["rxType"];
                modelRef.componentInstance.IsNotesLogPopupClosed.subscribe((res : any) => {
                    if(modelRef) {
                        modelRef.close();
                    }
                    if(res && res === 'add') {
                        this.openDurOrNotesTab(item, "addNotesfrmButton");
                    }
                    
                })
            } else {
                this.openDurOrNotesTab(item, 'add');
            }
       }
    }
    openAnnotationHistoryModal(item) {
        this.selectedItemForNoteHistory = null;
        this._cdr.detectChanges();
        this.selectedItemForNoteHistory = item;
    }

    openModal(item){
        var rxList = this.patientHistory.filter(x => x.PrescReFill.Id == item.PrescRefillId);
       const optedRx = rxList && rxList.length > 0 ?  rxList[0] : null;
       this.TotalRxInfo.push(optedRx);
       this.openBenefitCheckModel();
    }

    openBenefitCheckModel() {
        if(this.TotalRxInfo && this.TotalRxInfo.length) {
            this.isShowDrugValuesPop = true;
            this.drugInfo =  this.TotalRxInfo[0]["Drug"];
        }
    }
    processRefill(isFromPop: boolean = false) {
        if(isFromPop && this.TotalRxInfo && this.TotalRxInfo.length>1) {
            const totalRxInfo = [...this.TotalRxInfo];
            const rxPrescList =  totalRxInfo.splice(0, 1);
            this.rxInfo = totalRxInfo[0];
            if (this.rxInfo && (this.rxInfo.Prescription.PrescNum === rxPrescList[0].Prescription.PrescNum)) {
                this.TotalRxInfo.splice(0, 1)
                this.modalRefTrOut = this._modalService.open(this.CommonWarning,
                        {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"
                });
             }  else {
                this.TotalRxInfo = this.totalSelectedRphRxs;
                 this.processRefillFlow();
             }
         }  else {
           this.processRefillFlow();
        }
    }
    processRefillFlow() {
        if (this.modalRefTrOut) {
            this.modalRefTrOut.close();
        }
        const prescNum = this.TotalRxInfo.map(val => {
            return val["Prescription"]["PrescNum"];
        });
        this.closePopUp.emit("refill");
        const data = { selectedRxs: prescNum, frm: this.activeScreen === "rx" ? "RxHist" : "PatHis", completeData: prescNum,
         patHistData: this.activeScreen === "rx" ? this.SearchParams.value : this.TotalRxInfo[0]?.["Patient"]?.["patientid"]};
        if (this.activeScreen === "rx") {
            this.SearchParams.controls["refillFrmHist"].patchValue(true);
        }
        localStorage.setItem("previousRefillScreen", this._commonSer.encryptData("Refill"));
        this._commonStore.storeRefInfo(data);
        this._commonStore.storeRfStage("Start");
    }
    drugMissingValuesClosingPop(isSelectedTYpe) {
        if (isSelectedTYpe !== "cancel") {
            this.selectedRxForBenefitCheck =   this.TotalRxInfo[0];
            this.closePopUp.emit("BenefitCheck");
            this._patPreDrugModalServ._patPreDrugModal$.next("BenefitCheck");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.IsBenfitCheckfromRxHistory = true;
            modalRef.componentInstance.PrescReFill = this.TotalRxInfo[0]["PrescReFill"]["Id"];
            modalRef.componentInstance.SelectedRxDetails =  this.TotalRxInfo[0];
            modalRef.componentInstance.RxDetails = {PrescNum : this.TotalRxInfo[0]["Prescription"]["PrescNum"] ,
             Refill : this.TotalRxInfo[0]["PrescReFill"]["ReFillNum"] , FillNo:
             this._commonSer.checkIsPartialFill(this.TotalRxInfo[0]["PrescReFill"], this.TotalRxInfo[0]["Prescription"]) ?
             this.TotalRxInfo[0]["PrescReFill"]["PartialFillNo"] : " -- "
            };
            modalRef.componentInstance.CloseBenfitCheckModal
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(closed => {
                modalRef.close();
                this.isShowDrugValuesPop = false;
                if (closed === "Refill") {
                    this.processRefill(true);
                } else if (closed === "changeReq" || closed === "paReq") {
                    this.selectedBenefitCheckType = closed;
                    this.activeModal = null;
                    this._cdr.detectChanges();
                    this.activeModal = "ERxChange";
                } else {
                    this.loadRxhistory();
                }
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
        } else {
             this.isShowDrugValuesPop = false;
        }
    }
    loadRxhistory() {
        this.rxInfo = null;
        this.TotalRxInfo = [];
        this.loadRxHistoryEmit.emit(); 
    }
    sortDesc(arrayList) {
        let array = [];
        if(arrayList.length > 1){
            return array = arrayList.sort(function(a, b) {
                return b.PrescReFill.ReFillNum - a.PrescReFill.ReFillNum;
              });
        } else {
            return arrayList;
        }
      }
      getCurrentViewForSelectedRx() {
        const modelRef = this._modalService.open(CurrentViewComponent, {
            backdrop: false, centered: true,
            keyboard: false, windowClass: "max-modal-79"
        });
        modelRef.componentInstance.RxInfo = this.RxFGFormGroup?.value ? this.RxFGFormGroup.value : this.patientID;
        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(modelRef)
                modelRef.close();
        });
      }
}
